import { TypeAheadItemType, TypeAheadTypeType } from "helpers";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { SearchParamsStateType, useSearchParamsState } from "react-use-search-params-state";
import { useCurrentStates } from "state";
import {
  LocationResponseType,
  useBrowseApiRequirements,
  useBrowseSearchApi,
  useLocationSearch,
  useMapRequirements,
  validSearchParams,
} from "state/browse";

const filtersDefaults: SearchParamsStateType = {
  assumable_loan: { type: "boolean", default: false },
  hide_unknown_equity: { type: "boolean", default: false },
  listing_event: { type: "string", default: null, multiple: true },
  listing_remarks: { type: "string", default: null, multiple: true },
  listing_type: { type: "string", default: [], multiple: true },
  max_bathrooms: { type: "number", default: null },
  max_bedrooms: { type: "number", default: null },
  max_days_on_market: { type: "number", default: null },
  max_equity: { type: "number", default: null },
  max_gross_gain: { type: "number", default: null },
  max_gross_income: { type: "number", default: null },
  max_listing_price: { type: "number", default: null },
  max_lot_square_feet: { type: "number", default: null },
  max_square_feet_finished: { type: "number", default: null },
  max_unfinished_basement: { type: "number", default: null },
  max_updated: { type: "number", default: null },
  max_year_built: { type: "number", default: null },
  min_bathrooms: { type: "number", default: null },
  min_bedrooms: { type: "number", default: null },
  min_days_on_market: { type: "number", default: null },
  min_equity: { type: "number", default: null },
  min_gross_gain: { type: "number", default: null },
  min_gross_income: { type: "number", default: null },
  min_listing_price: { type: "number", default: null },
  min_lot_square_feet: { type: "number", default: null },
  min_square_feet_finished: { type: "number", default: null },
  min_unfinished_basement: { type: "number", default: null },
  min_updated: { type: "number", default: null },
  min_year_built: { type: "number", default: null },
  structure_type: { type: "string", default: [], multiple: true },
  geoId: { type: "number", default: null },
  geoIds: { type: "number", default: null },
};

export const locationsDefaults: SearchParamsStateType = {
  city: { type: "number", default: [], multiple: true },
  county: { type: "number", default: [], multiple: true },
  metro_area: { type: "number", default: [], multiple: true },
  neighborhood: { type: "number", default: [], multiple: true },
  zip_code: { type: "string", default: [], multiple: true },
};

export const paginationDefaults: SearchParamsStateType = {
  limit: { type: "number", default: 500 },
  offset: { type: "number", default: 0 },
};

export const mapBoundsDefaults: SearchParamsStateType = {
  mapBounds: { type: "string", default: null },
};

const convertToTypeAheadLocations = (locations: LocationResponseType | undefined): TypeAheadItemType[] => {
  if (!locations) return [];
  const typeAheadLocations: TypeAheadItemType[] = [];
  validSearchParams.forEach((key) => {
    locations[key as keyof LocationResponseType].forEach((value) => {
      const newLocation = { ...value, type: key as TypeAheadTypeType };
      typeAheadLocations.push(newLocation as TypeAheadItemType);
    });
  });

  return typeAheadLocations;
};

export const useBrowse = () => {
  const [locationParams] = useSearchParamsState(locationsDefaults);
  const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);
  const [prevFilterParams, setPrevFilterParams] = useState(filterParams);
  const [paginationParams, setPaginationParams] = useSearchParamsState(paginationDefaults);
  const [prevPaginationParams, setPrevPaginationParams] = useState(paginationParams);
  const mapBoundsParam = useSearchParamsState(mapBoundsDefaults)[0].mapBounds;
  const browseRequirements = useBrowseApiRequirements(locationParams, filterParams.geoId);
  const { setCurrentStates } = useCurrentStates();
  const { setLocationSearchItems } = useLocationSearch();
  const searchApi = useBrowseSearchApi(
    browseRequirements.locationData,
    filterParams,
    paginationParams,
    mapBoundsParam,
  );
  const { setMapRequirements } = useMapRequirements();

  useEffect(() => {
    if (browseRequirements.error && filterParams.geoId) {
      // Try to recover from the error by clearing the geoId
      setFilterParams({ geoId: null, geoIds: null });
    }
  }, [browseRequirements, filterParams, setFilterParams]);

  useEffect(() => {
    if (!browseRequirements.isLoading && browseRequirements.locationData) {
      const stateIds = browseRequirements.locationData.state.map((state) => state.id);
      setCurrentStates(stateIds);
      setLocationSearchItems(convertToTypeAheadLocations(browseRequirements.locationData));
      setMapRequirements({
        polygonsData: browseRequirements.polygonsData,
        geoJsonData: browseRequirements.geoJsonData,
      });
      if (paginationParams.offset !== 0) {
        setPaginationParams({ offset: 0 });
      } else {
        // console.log("Locations Change", browseRequirements, mapBoundsParam);
        searchApi.triggerSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(browseRequirements.locationData), mapBoundsParam]);

  useEffect(() => {
    if (!isEqual(filterParams, prevFilterParams)) {
      setPrevFilterParams(filterParams);
      if (paginationParams.offset !== 0) {
        setPaginationParams({ offset: 0 });
      } else {
        // console.log("filterParams Change", browseRequirements);
        searchApi.triggerSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterParams)]);

  useEffect(() => {
    if (!isEqual(paginationParams, prevPaginationParams)) {
      setPrevPaginationParams(paginationParams);
      // console.log("paginationParams Change", browseRequirements);
      searchApi.triggerSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(paginationParams)]);

  return {
    loading: searchApi.loading,
    emptyResult: false,
  };
};

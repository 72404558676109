import {
  highlightHandlerAtom,
  PropertyListItem,
  PropertyListItemWholesale,
  PropertyPhotosTable,
} from "components/browse";
import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { useRegisterClientModal, useUserSession } from "state";
import { SearchResultType } from "state/browse";
import { useSelectedProperty } from "state/ui";

type Props = {
  properties: SearchResultType[];
  selectedProperty?: any;
};

export const PropertiesList = ({ properties, selectedProperty }: Props) => {
  const { setSelectedProperty } = useSelectedProperty();
  const ref = useRef<HTMLDivElement>(null);
  const highlightHandler = useRecoilValue(highlightHandlerAtom);
  const currentUser = useUserSession();
  const { openRegisterClientModal } = useRegisterClientModal();

  const onMouseHover = (propertyId: number | null) => {
    // Highlight property on Map
    if (highlightHandler) {
      highlightHandler(propertyId);
    }
  };

  useEffect(() => {
    ref?.current?.scrollTo({ left: 0, behavior: "smooth" });
  }, [selectedProperty, properties, setSelectedProperty]);

  const photosProperty = selectedProperty ?? properties[0];

  return (
    <div>
      <div className="sticky top-0 overflow-y-scroll bg-gray-100" ref={ref}>
        {photosProperty?.parcel_id && (
          <div className="relative">
            {!currentUser.isClient && photosProperty.is_wholesale ? (
              <div className="flex h-56 min-w-full flex-col whitespace-nowrap rounded-lg border bg-gray-50">
                {/* <PinIcon className="absolute left-2 top-0 z-10 mt-2 mr-2 h-5 w-5 text-black shadow-xl" /> */}
                <div className="relative flex aspect-video w-full flex-col items-center justify-center gap-2">
                  <div className="text-md font-bold">Wholesale Listing</div>
                  <div className="text-sm">
                    {" "}
                    Must be{" "}
                    <span className="cursor-pointer text-blue-400" onClick={() => openRegisterClientModal()}>
                      registered
                    </span>{" "}
                    to view
                  </div>
                </div>
              </div>
            ) : (
              <PropertyPhotosTable property={photosProperty} />
            )}
          </div>
        )}
      </div>
      <div className="">
        <table className="w-full text-left">
          <thead className="w-full">
            <tr className="sticky top-56 z-40 shadow-sm shadow-y shadow-gray-200 text-xs text-gray-600 bg-white">
              <th className="p-2 font-light">Address</th>
              <th className="hidden p-2 font-light xl:table-cell text-center">Last Updated</th>
              <th className="p-2 font-light uppercase text-center">price</th>
              <th className="p-2 font-light uppercase text-center">arv</th>
              <th className="hidden p-2 font-light uppercase xl:table-cell text-center">piti</th>
              <th className="hidden p-2 font-light uppercase xl:table-cell text-center">rent</th>
              <th className="p-2 font-light uppercase">&nbsp;</th>
            </tr>
          </thead>
          <tbody className="w-full">
            {properties.map((property: any, idx: number) => (
              <tr
                key={property.parcel_id}
                className={`cursor-pointer border-t first:border-t-0 align-top ${
                  property.parcel_id === photosProperty?.parcel_id ? "bg-gray-100" : "border-gray"
                }`}
                onMouseEnter={() => onMouseHover(property.parcel_id)}
                onMouseLeave={() => onMouseHover(null)}
                onClick={() => setSelectedProperty(property)}
              >
                {!currentUser.isClient && property.is_wholesale ? (
                  <PropertyListItemWholesale
                    key={idx}
                    property={property}
                    selected={property.parcel_id === photosProperty?.parcel_id}
                  />
                ) : (
                  <PropertyListItem
                    key={idx}
                    property={property}
                    selected={property.parcel_id === photosProperty?.parcel_id}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

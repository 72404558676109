import { Wrapper } from "@googlemaps/react-wrapper";
import { appConfig } from "config";
import { cn } from "helpers";
import { useEffect, useRef, useState } from "react";

type StreetViewStatusType = {
  available: boolean;
  lat: number;
  lng: number;
  heading: number;
};

const defaultStreetViewStatus: StreetViewStatusType = {
  available: false,
  lat: 0,
  lng: 0,
  heading: 0,
};

const Panorama = ({
  streetViewStatus,
  titles,
  rounded,
}: {
  streetViewStatus: StreetViewStatusType;
  titles: boolean;
  rounded?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && streetViewStatus.available) {
      const streetViewOptions: google.maps.StreetViewPanoramaOptions = {
        scrollwheel: false,
        position: { lat: streetViewStatus.lat, lng: streetViewStatus.lng },
        pov: { heading: streetViewStatus.heading, pitch: 0 },
        zoom: 0,
        motionTracking: false,
        controlSize: 30,
      };
      if (!titles) {
        streetViewOptions.disableDefaultUI = true;
        streetViewOptions.clickToGo = false;
        streetViewOptions.fullscreenControl = true;
      }
      new google.maps.StreetViewPanorama(ref.current, streetViewOptions);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, streetViewStatus]);

  return <div ref={ref} className={`size-full ${rounded}`} />;
};

type Props = {
  parcel: any;
  titles?: boolean;
  sizeClasses: string;
  otherClasses?: string;
  rounded?: string;
  inErrorImage?: string;
  load?: boolean;
};

export const StreetView = ({
  parcel,
  titles = true,
  sizeClasses,
  otherClasses,
  rounded,
  inErrorImage,
  load = true,
}: Props) => {
  const [streetViewStatus, setStreetViewStatus] = useState<StreetViewStatusType>(defaultStreetViewStatus);
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [streetViewService, setStreetViewService] = useState<google.maps.StreetViewService | null>(null);

  
  useEffect(() => {
    if (load && googleMapsReady && !streetViewService) {
      const service = new google.maps.StreetViewService();
      setStreetViewService(service);
    }
  }, [load, streetViewService, googleMapsReady]);

  const getStreetViewCameraPosition = (lat: number, lng: number) => {
    if (!streetViewService) return;
    const requestData = {
      location: { lat, lng },
      radius: 100,
      preference: google.maps.StreetViewPreference.NEAREST,
      source: google.maps.StreetViewSource.OUTDOOR,
    };

    streetViewService.getPanorama(requestData).then((resp: any) => {
      const svCameraPosition: StreetViewStatusType = {
        available: true,
        lat: resp.data.location.latLng.lat(),
        lng: resp.data.location.latLng.lng(),
        heading: 0,
      };
      const start = new google.maps.LatLng(svCameraPosition.lat, svCameraPosition.lng);
      const stop = new google.maps.LatLng(lat, lng);
      svCameraPosition.heading = google.maps.geometry.spherical.computeHeading(start, stop);
      setStreetViewStatus(svCameraPosition);
    });
  };

  const onGoogleMapsLoadingStatus = (status: string) => {
    if (status === "SUCCESS") {
      setGoogleMapsReady(true);
    }
  };

  useEffect(() => {
    if (streetViewService && googleMapsReady) {
      getStreetViewCameraPosition(parcel.latitude, parcel.longitude);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapsReady, streetViewService, parcel.latitude, parcel.longitude]);

  return (
    <Wrapper apiKey={appConfig.googleMapsKey} callback={onGoogleMapsLoadingStatus}>
      <div className={`w-full ${sizeClasses} ${inErrorImage && "z-0"}`}>
        {streetViewStatus.available ? (
          <div className={cn(`w-full ${sizeClasses} ${otherClasses}`)}>
            <Panorama streetViewStatus={streetViewStatus} titles={titles} rounded={rounded} />
          </div>
        ) : (
          <div
            className={`w-full ${sizeClasses} flex items-center justify-center border border-[#EDE9D6] bg-[#fdf7e5]`}
            style={inErrorImage ? { backgroundImage: `url("${inErrorImage}")` } : {}}
          >
            {(!inErrorImage || !parcel.latitude || !parcel.longitude) && `Street View not available`}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

import { useEffect, useState } from "react";
import { StructureTypeOption } from "state/browse";

type STProps = {
  type: StructureTypeOption;
  active: boolean;
  onClick: any;
};

export const StructureTypeButton = ({ type, active, onClick }: STProps) => {
  const [isActive, setIsActive] = useState(active);

  const toggleActive = () => {
    const newState = !isActive;
    setIsActive(newState);
    onClick(type.value, newState);
  };

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div
      className={`flex w-24 cursor-pointer flex-col items-center justify-center rounded-lg border px-3 pt-3 pb-2 ${
        isActive ? "bg-gray-900 text-gray-300" : "text-gray-400"
      }`}
      onClick={toggleActive}
    >
      <img
        src={`${
          isActive
            ? `https://cdn.davinci.pellego.com/static/images/${type.value}.svg`
            : `https://cdn.davinci.pellego.com/static/images/black/${type.value}.svg`
        }`}
        className={`h-5 w-5 ${isActive ? "opacity-70" : "opacity-50"}`}
        alt={type.name}
      />
      {type.name}
    </div>
  );
};

import { atom, useRecoilState } from "recoil";
import { TypeAheadItemType } from "helpers";

export interface ILocationSearchItem {
  label: string;
  value: any;
};

export const locationSearchItemsAtom = atom<TypeAheadItemType[]>({
  key: "locationSearchItemsAtom",
  default: [],
});


export const useLocationSearch = () => {
  const [locationSearchItems, setLocationSearchItems] = useRecoilState(locationSearchItemsAtom);

  return {
    locationSearchItems,
    setLocationSearchItems,
  };

};
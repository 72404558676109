import { MultiPolygon, Position } from "geojson";
import { atom, useRecoilState } from "recoil";
import { fetchBoxAppreciations, fetchLocationAppreciations } from "state";
import { useLocationSearch } from "state/browse";

const fetchLocationAppreciationsFromSearchItems = async (locationSearchItems: any[]) => {
  let locationAppreciations = {};
  const locations: { type: string; id: number }[] = [];

  locationSearchItems.forEach((location) => {
    if (location.type === "city" || location.type === "county" || location.type === "metro_area") {
      locations.push({ type: location.type, id: Number(location.id!) });
    }
  });

  if (locations.length > 0) {
    const allLocationAppreciations = await fetchLocationAppreciations(locations);
    allLocationAppreciations.forEach((locationAppreciation) => {
      locationAppreciations = { ...locationAppreciations, ...locationAppreciation };
    });
  }

  return locationAppreciations;
};

type MapRequiremetsType = {
  polygonsData: MultiPolygon[] | null;
  geoJsonData: Position[][][] | null;
};

export const mapRequiremetsAtom = atom<MapRequiremetsType | null>({
  key: "mapRequiremetsAtom",
  default: null,
});

export const useMapRequirements = () => {
  const { locationSearchItems } = useLocationSearch();
  const [mapRequirements, setMapRequirements] = useRecoilState(mapRequiremetsAtom);

  return {
    loading: !mapRequirements,
    setMapRequirements,
    locationsPolygons: mapRequirements?.polygonsData,
    geoJsonPolygons: mapRequirements?.geoJsonData,
    fetchLocationAppreciations: async () => fetchLocationAppreciationsFromSearchItems(locationSearchItems),
    fetchBoxAppreciations: async (slat: number, elng: number, nlat: number, wlng: number) =>
      fetchBoxAppreciations(slat, elng, nlat, wlng),
  };
};

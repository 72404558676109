import { appConfig } from "config"; // Adjust the import based on your project structure
import { fetcherWithCredentials, prettifyParcel } from "helpers";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useCurrentStates, useUserSession } from "state";
import { customStructureAtom, FinancialsClass, useFinancials, useProforma } from "state/proformas";
import {
  buildRentalCompsFilters,
  buildSalesCompsFilters,
  processRentalComparables,
  processSalesComparables,
  useRentalComps,
  useSalesComps,
  useSalesCompsFilters,
} from "state/proformas/comparables";
import useSWR from "swr";

/**
 * Return a listing object with an on market indicator.
 * @return {!Object} A formatted listing.
 */
const getListing = (parcel: any) => {
  const defaultListing = {
    listing_prices: [],
  };
  const originalListing = parcel.most_recent_listing;
  const listing = originalListing ? { ...originalListing } : defaultListing;

  const isNotSold = !listing.sale_price;
  const hasListingPrices = (listing.listing_prices || []).length > 0;

  listing._on_market = isNotSold && hasListingPrices && listing.listing_status === "Active";
  return listing;
};

interface UseProformasByDataResult {
  isBasicLoading: boolean;
  isRestLoading: boolean;
  isBasicError: boolean;
  isRestError: boolean;
}

export const useProformasByData = (
  data: string | undefined,
  proforma_type: string | undefined,
  data_type: "address" | "listingNumber",
  share_token: string | null,
): UseProformasByDataResult => {
  const [isBasicLoading, setIsBasicLoading] = useState(true);
  const [isRestLoading, setIsRestLoading] = useState(true);
  const currentUser = useUserSession();
  const isAgent = currentUser.isAgent;
  const isClient = currentUser.isClient;
  const urlParams = new URLSearchParams({
    proforma_type: proforma_type || "",
  });
  if (share_token) {
    urlParams.append("share-token", share_token);
  }

  const basicApiUrl = `${appConfig.apiEndpoint.proformaAddressBasic.replace(":full_address", data || "")}?${urlParams.toString()}`;

  const restApiUrl =
    data_type === "address"
      ? `${appConfig.apiEndpoint.proformaAddress.replace(":full_address", data || "")}?${urlParams.toString()}`
      : `${appConfig.apiEndpoint.proformaListingNumber.replace(":listing_number", data || "")}?${urlParams.toString()}`;

  const { data: basicOriginalData, error: basicError } = useSWR(
    () => (data_type === "address" ? [basicApiUrl, isAgent, isClient] : null),
    fetcherWithCredentials,
    {
      revalidateIfStale: false,
      shouldRetryOnError: false,
    },
  );

  const {
    data: restOriginalData,
    error: restError,
    isLoading: isLoadingRest,
  } = useSWR([restApiUrl, isAgent, isClient], fetcherWithCredentials, {
    revalidateIfStale: false,
    shouldRetryOnError: false,
  });

  const isBasicError = !!basicError;
  const isRestError = !!restError;

  const { setCurrentStates } = useCurrentStates();
  const { proforma, setProforma } = useProforma();
  const { setSalesComparables } = useSalesComps();
  const { setRentalComparables, setRentalCompsFilters } = useRentalComps();
  const setCustomStructure = useSetRecoilState(customStructureAtom);
  const { setSalesCompFilters, setSalesCompFiltersBackup } = useSalesCompsFilters();
  const { setFinancials } = useFinancials();

  useEffect(() => {
    const basicData = { ...basicOriginalData };
    if (data_type !== "listingNumber" && basicOriginalData && isLoadingRest) {
      basicData.isDataComplete = false;
      if (basicData.parcel) {
        if (!basicData.parcel.latitude || !basicData.parcel.longitude) {
          basicData.isGeoData = false;
        } else {
          basicData.isGeoData = true;
        }
        basicData.photos = basicOriginalData.photos;
        basicData.listing = getListing(basicData.parcel);
        basicData.parcel = prettifyParcel(basicData.parcel);
        setCurrentStates([basicData.parcel.state_id]);
        setProforma(basicData);
        setIsBasicLoading(false);
      }
    }
  }, [data_type, basicOriginalData, isLoadingRest, setProforma, setCurrentStates, setIsBasicLoading]);

  useEffect(() => {
    const restData = { ...restOriginalData };
    if (restOriginalData) {
      restData.isDataComplete = true;
      if (restData.parcel) {
        if (!restData.parcel.latitude || !restData.parcel.longitude) {
          restData.isGeoData = false;
        } else {
          restData.isGeoData = true;
        }
        restData.photos = restOriginalData.photos;
        restData.listing = getListing(restData.parcel);
        restData.parcel = prettifyParcel(restData.parcel);
        setCurrentStates([restData.parcel.state_id]);
      }
      setProforma(restData);
      if (restData.comparables) {
        // const salesComps = processSalesComparables(restData.comparables);
        // Temporary filter to avoid showing sales comparables with most_recent_listing === null
        const salesComps = processSalesComparables(
          restData.comparables.filter((comp: any) => comp.parcel.most_recent_listing),
        );
        setSalesComparables(salesComps);
        const salesCompFilters = buildSalesCompsFilters(
          salesComps,
          restData.parcel._characteristics.structure_type,
        );
        setSalesCompFilters(salesCompFilters);
        setSalesCompFiltersBackup(salesCompFilters);
      }
      if (restData.rental_comparables) {
        const rentalComps = processRentalComparables(restData.rental_comparables);
        setRentalComparables(rentalComps);
        setRentalCompsFilters(
          buildRentalCompsFilters(rentalComps, restData.parcel._characteristics.structure_type),
        );
      }
      if (restData.custom_structure) {
        setCustomStructure(restData.custom_structure);
      }
      if (restData.calcsheet) {
        const newFinancials = new FinancialsClass(null, restData);
        setFinancials(newFinancials.data);
      }
      setIsBasicLoading(false);
      setIsRestLoading(false);
    }
  }, [
    restError,
    restOriginalData,
    setProforma,
    setCurrentStates,
    setSalesComparables,
    setRentalComparables,
    setCustomStructure,
    setSalesCompFilters,
    setSalesCompFiltersBackup,
    setRentalCompsFilters,
    setFinancials,
    setIsBasicLoading,
    setIsRestLoading,
  ]);

  useEffect(() => {
    if (restError && !proforma.isRestError) {
      setProforma({ ...proforma, isRestError: true });
      setIsRestLoading(false);
    }
  }, [proforma, restError, setProforma, setIsRestLoading]);

  return {
    isBasicLoading,
    isRestLoading,
    isBasicError,
    isRestError,
  };
};

export const CompSkeleton = () => {
  const baseColor = "bg-gray-400/10";

  var boxes = [];
  boxes.push(
    <div key={"box-0"} className={`loading block h-[320px] w-full rounded-md sm:hidden ${baseColor}`} />,
  );
  for (let i = 1; i < 4; i++) {
    const box = (
      <div
        key={"box-" + i}
        className={`loading hidden h-[320px] w-full max-w-[410px] rounded-md sm:block ${baseColor}`}
      />
    );
    boxes.push(box);
  }

  const cardLines = [];
  for (let i = 0; i < 11; i++) {
    const cardLine = <div key={"cardLine-" + i} className={`loading h-4 w-full rounded-md ${baseColor}`} />;
    cardLines.push(cardLine);
  }

  var cards = [];
  for (let i = 0; i < 5; i++) {
    const card = (
      <div
        key={"card-" + i}
        className="mt-[36px] flex h-[643px] w-[256px] shrink-0 flex-col items-center rounded-md border border-gray-400/10"
      >
        <div className="flex w-full flex-col justify-start space-y-4 px-4">
          <div className={`loading mb-10 mt-[77px] h-[144px] w-full rounded-md ${baseColor}`} />
          {cardLines}
        </div>
      </div>
    );
    cards.push(card);
  }

  return (
    <div className="flex w-full max-w-7xl flex-col items-center">
      <div className="flex w-full flex-col items-center px-4">
        <div className={`loading mb-[72px] mt-[88px] h-11 w-full rounded-md max-w-72 sm:max-w-96 ${baseColor}`} />
        <div className="flex w-full flex-row justify-between gap-4 px-4 sm:px-0">{boxes}</div>
      </div>
      <div className="mb-10 flex w-full flex-row justify-start gap-4 overflow-x-hidden pl-4">{cards}</div>
    </div>
  );
};

import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://61ac428984d81df52f81516ca300fd77@o4507861897510912.ingest.us.sentry.io/4507861901901824",
  integrations: [],
  allowUrls: [/https:\/\/app\.(lotside|pellego)\.com/],
  // This is a workaround for the issue where Sentry captures all google recaptcha "Timeout" errors
  // beforeSend(event, hint) {
  //   if (hint.originalException === "Timeout") return null;
  //   return event;
  // }
});
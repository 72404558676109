import { Disclosure } from "@headlessui/react";
import { ExternalLinkIcon, LoginIcon, LogoutIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import { useUserActions } from "actions";
import { Filters } from "components/browse";
import { SearchBox } from "components/SearchBox";
import { isPellego, isStage } from "config";
import { cn, logosFromState } from "helpers";
import { Fragment } from "react";
import { isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import { useCurrentStates, useUserSession } from "state";
import { SearchResultType } from "state/browse";

type Props = {
  loading?: boolean;
  emptyResult?: boolean;
  searchResults?: SearchResultType[];
  notFixed?: boolean;
};

export const NavBrowse = ({ loading, emptyResult, searchResults, notFixed }: Props) => {
  const currentUser = useUserSession();
  const userActions = useUserActions();
  const location = useLocation();
  const { currentStates } = useCurrentStates();
  const { smallLogoUrl, logoUrl } = logosFromState(currentStates);

  return (
    <Disclosure as="nav" className={`z-50 w-full border-b bg-white py-3 text-gray-600`}>
      {({ open }) => (
        <>
          <div className="mx-auto pl-2">
            <div className="relative flex items-center justify-between">
              <div className="inset-y-0 right-2 flex items-center">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 pl-0 text-gray-600 hover:text-gray-400 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex w-10 flex-shrink-0 items-center xl:hidden">
                  <Link to="/">
                    <img
                      className="h-7 w-auto"
                      src={smallLogoUrl}
                      alt={isPellego && !isStage ? "Pellego" : "Lotside"}
                    />{" "}
                  </Link>
                </div>
                <div
                  className={cn(
                    isPellego && !isStage ? "w-36" : "w-20",
                    "hidden flex-shrink-0 items-center xl:flex",
                  )}
                >
                  <Link to="/">
                    <img
                      className="h-7 w-auto"
                      src={logoUrl}
                      alt={isPellego && !isStage ? "Pellego" : "Lotside"}
                    />{" "}
                  </Link>
                </div>
              </div>

              <SearchBox />
              {isMobile && !loading && !emptyResult && <Filters filteredProperties={searchResults!} />}
            </div>
          </div>

          {/* Mobile Menu */}

          <Disclosure.Panel className="fixed h-screen w-full max-w-md overflow-hidden bg-white pt-2">
            {!currentUser.isLogged && (
              <>
                <div className="xs:mt-4 relative ml-3 text-left text-sm uppercase text-gray-500">
                  <ul>
                    <li className="px-4 pt-4 font-medium">
                      <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                      <a
                        href={`https://www.${isPellego ? "pellego" : "lotside"}.com/about`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        About {isPellego ? "Pellego" : "Lotside"}
                      </a>
                    </li>
                    <li className="px-4 pt-4 font-medium">
                      <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                      <a
                        href={`https://www.${isPellego ? "pellego" : "lotside"}.com/plans/agents`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Agent&nbsp;Solutions
                      </a>
                    </li>

                    <li className="px-4 pt-4 font-medium">
                      <a
                        href={`https://www.${isPellego ? "pellego" : "lotside"}.com/buyers`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                        Buyer&nbsp;Solutions
                      </a>
                    </li>

                    <li className="mx-4 mb-4 mt-6 border-b-2 border-gray-100"></li>
                    <li className="px-4 py-2">
                      <Disclosure.Button as={Fragment}>
                        <Link
                          to="/login"
                          state={{ from: location }}
                          className="flex-nowrap text-sm font-medium uppercase"
                        >
                          <LoginIcon className="mr-4 inline h-6 w-6" aria-hidden="true" /> Sign&nbsp;In
                        </Link>
                      </Disclosure.Button>
                    </li>
                    <li className="px-4 py-4">
                      <Disclosure.Button as={Fragment}>
                        <Link
                          to="/signup"
                          className="flex-nowrap py-2 text-sm font-medium uppercase text-gray-500"
                        >
                          <PencilAltIcon className="mr-4 inline h-6 w-6" aria-hidden="true" /> Sign Up for
                          free
                        </Link>
                      </Disclosure.Button>
                    </li>
                  </ul>
                </div>
              </>
            )}

            {/* Profile dropdown */}
            {currentUser.isLogged && (
              <div className="xs:mt-4 relative ml-3 text-left text-sm uppercase text-gray-500">
                <ul>
                  <li className="px-4 py-2">
                    <Disclosure.Button as={Fragment}>
                      <Link to="/account">
                        <img
                          className="inline-block size-20 rounded-md object-cover"
                          src={currentUser.avatar}
                          alt="avatar"
                        />
                      </Link>
                    </Disclosure.Button>
                  </li>
                  <li className="px-4 pt-2 text-left">
                    <div className="text-xs text-gray-600">Welcome back</div>
                    {currentUser.name && (
                      <div className="text-lg font-bold">
                        <Disclosure.Button as={Fragment}>
                          <Link to="/account">{currentUser.name}</Link>
                        </Disclosure.Button>
                      </div>
                    )}
                  </li>
                  <li className="px-4 pt-0 text-xs">
                    <Disclosure.Button as={Fragment}>
                      <Link to="/account">Edit your profile</Link>
                    </Disclosure.Button>
                  </li>
                  <li className="mx-4 mb-3 mt-6 border-b-2 border-gray-700"></li>
                  <li className="px-4 pt-8 font-medium">
                    <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                    <a
                      href={`https://www.${isPellego ? "pellego" : "lotside"}.com/about`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      About {isPellego ? "Pellego" : "Lotside"}
                    </a>
                  </li>
                  <li className="px-4 pt-4 font-medium">
                    <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                    <a
                      href={`https://www.${isPellego ? "pellego" : "lotside"}.com/plans/agents`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Agent&nbsp;Solutions
                    </a>
                  </li>
                  <li className="px-4 pt-4 font-medium">
                    <a
                      href={`https://www.${isPellego ? "pellego" : "lotside"}.com/buyers`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ExternalLinkIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                      Buyer&nbsp;Solutions
                    </a>
                  </li>
                  <li className="mx-4 mb-2 mt-6 border-b-2 border-gray-700"></li>
                  <li className="px-4 py-4">
                    <Disclosure.Button as={Fragment}>
                      <Link
                        to="/"
                        className="flex-nowrap text-sm font-medium uppercase text-gray-500"
                        onClick={userActions.logout}
                      >
                        <LogoutIcon className="mr-4 inline h-6 w-6" aria-hidden="true" />
                        Sign out
                      </Link>
                    </Disclosure.Button>
                  </li>
                </ul>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

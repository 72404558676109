import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, SubmitButton, UnLabeledInput } from "components/forms";
import { appConfig, isPellego } from "config";
import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { RegisterRestrictedInfoContactType, useRegisterRestrictedInfoContact } from "state";
import * as Yup from "yup";


export const RegisterClientOrAgentForm = () => {
  const { registerRestrictedInfoContact } = useRegisterRestrictedInfoContact();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  // form validation rules
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Please enter your full name"),
    phone_number: Yup.string().required("Your phone number is required"),
    email: Yup.string().email().required("Your email address is required"),
    has_agent_license: Yup.boolean(),
    is_pre_approved: Yup.boolean(),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, setFocus, formState } =
    useForm<RegisterRestrictedInfoContactType>(formOptions);
  const { errors, isSubmitting, isSubmitSuccessful } = formState;

  const domain = isPellego ? "pellego.com" : "lotside.com";
  const agentsURL = `https://www.${domain}/plans/agents`;

  const onSubmit = async (formData: RegisterRestrictedInfoContactType) => {
    await reCaptchaRef.current?.reset();
    formData.domain = domain;
    formData["g-recaptcha-response"] = (await reCaptchaRef.current?.executeAsync()) || "";

    return registerRestrictedInfoContact(formData);
  };

  useEffect(() => {
    setFocus("full_name");
  }, [setFocus]);

  return (
    <div className="mx-auto mt-4 max-w-2xl px-8 pb-4">
      {isSubmitSuccessful ? (
        <div className="text-center text-lg">
          Thank you for registering.
          <br />
          We will be in touch soon.
        </div>
      ) : (
        <>
          <h3 className="mb-2 mt-6 text-center text-xl">See Restricted Information</h3>
          <div className="w-full pt-2 text-sm">
            Some information is restricted by the the state. local MLS, or board of reactors. You can view
            this information with an agent account or a client account. As an agent, you must{" "}
            <a href={agentsURL} target="_blank" rel="noreferrer" className="text-[#6389c4]">
              hang your license with {isPellego ? "Pellego" : "Lotside"}
            </a>
            . As a client, you must sign a non-exclusive agency agreement. You will not be required to use our
            agents or buy, but we will ask if you are pre-approved to demonstrate intent. If you are not
            pre-approved, we can introduce you to lenders.
          </div>
          <div className="mt-2 max-w-md">
            <div className="grid grid-cols-1">
              <form onSubmit={handleSubmit(onSubmit)}>
                <section id="personal-data">
                  <UnLabeledInput
                    type="text"
                    label="Full Name"
                    {...register("full_name")}
                    errMsg={errors.full_name?.message}
                  />
                  <UnLabeledInput
                    type="text"
                    label="Phone Number"
                    {...register("phone_number")}
                    errMsg={errors.phone_number?.message}
                  />
                  <UnLabeledInput
                    type="email"
                    label="Email Address"
                    {...register("email")}
                    errMsg={errors.email?.message}
                  />
                </section>

                <section id="extras-selected">
                  <div className="mt-3 flex flex-col gap-1">
                    <Checkbox {...register("has_agent_license")}>I have an agent license</Checkbox>
                    <Checkbox {...register("is_pre_approved")}>I am pre-approved</Checkbox>
                  </div>
                </section>

                <div className="flex flex-row gap-2">
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    buttonClassName="text-white bg-[#7AA0BA] hover:bg-[#4f7b98] my-3"
                    disabled={isSubmitting}
                    isSuccessful={isSubmitSuccessful && !isSubmitting}
                  >
                    Submit
                  </SubmitButton>

                  <div className="content-center text-[0.5rem] text-gray-700 sm:text-[0.6rem]">
                    <ReCAPTCHA size="invisible" sitekey={appConfig.reCaptchaPublicKey} ref={reCaptchaRef} />
                    This site is protected by reCAPTCHA and the Google{" "}
                    <a href="https://policies.google.com/privacy" className="underline">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href="https://policies.google.com/terms" className="underline">
                      Terms of Service
                    </a>{" "}
                    apply.
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
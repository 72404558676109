import { atom, useRecoilState } from "recoil";


export const currentStatesAtom = atom<number[]>({
  key: "currentStatesAtom",
  default: [],
});

export const useCurrentStates = () => {
  const [currentStates, setCurrentStates] = useRecoilState(currentStatesAtom);
  return {
    currentStates,
    setCurrentStates,
  };
};
import { mdiAutorenew, mdiClose, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { WithSignIn } from "components";
import { buildLocationsFromSearchBoxOption, RecentSearchResultItem, SavedSearchResultItem, SearchResultItem } from "components/SearchBox";
import { isPellego } from "config";
import { cn, typeAheadApi, TypeAheadItemType } from "helpers";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-storage-complete";
import { useUserSession } from "state";
import { ISearchParamLocations, useLocationSearch } from "state/browse";
import { SaveSearchType, useSavedSearchList } from "state/proformas";
import { useDebounce, useOnClickOutside } from "usehooks-ts";


const browseURL = (locations: ISearchParamLocations) => {
  const nameValue: string[] = [];

  Object.entries(locations).forEach(([key, values]) => {
    values.forEach((value: any) => {
      nameValue.push(`${key}=${value}`);
    });
  });

  return "/browse?" + nameValue.join("&");
};

export const SearchBoxHome = () => {
  const [inputText, setInputText] = useState("");
  const debouncedText = useDebounce(inputText, 700);
  const [typeaheadResult, setTypeaheadResult] = useState<TypeAheadItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const [showInputPanel, setShowInputPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);
  const { locationSearchItems, setLocationSearchItems } = useLocationSearch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation();

  const currentUser = useUserSession();
  const isLogged = currentUser.isLogged;
  const [lsSearches] = useLocalStorage<any[]>("searches", [], {
    prefix: isPellego ? "pellego" : "lotside",
  });
  const recentSearches = lsSearches?.filter((lsSearch) => lsSearch.parameters.isRecent === true);
  const { savedSearchFilters: savedSearches } = useSavedSearchList(currentUser?.session?.user_id);

  const currentStateId = locationSearchItems[0]?.state_id || null;

  useEffect(() => {
    if (location.pathname !== "/browse" && locationSearchItems.length > 0) {
      setLocationSearchItems([]);
    }
  }, [location, locationSearchItems.length, setLocationSearchItems]);

  useEffect(() => {
    if (debouncedText.length > 1) {
      setLoading(true);
      typeAheadApi(debouncedText)
        .then((result) => {
          setTypeaheadResult(result);
          const notParcelResults = result.filter((item) => item.type !== "parcel");
          const parcelResults = result.filter((item) => item.type === "parcel");
          setTypeaheadResult([...notParcelResults, ...parcelResults]);
        })
        .finally(() => setLoading(false));
    }
  }, [debouncedText]);

  const firstNonParcelItem = typeaheadResult.find((item) => item.type !== "parcel");
  const firstParcelItem = typeaheadResult.find((item) => item.type === "parcel");

  const resetStatus = (closePanel: boolean = true) => {
    setInputText("");
    setTypeaheadResult([]);
    setSelectedItem(undefined);
    if (closePanel) {
      setShowInputPanel(false);
    }
  };

  useOnClickOutside(ref, () => resetStatus());

  const onInputTextChange = (e: any) => {
    if (e.target.value.length > 0) {
      setInputText(e.target.value);
    } else {
      resetStatus(false);
    }
  };

  const updateLocationsAndNavigation = (items: TypeAheadItemType[]) => {
    const [locations, newLocationSearchItems] = buildLocationsFromSearchBoxOption(items);

    setLocationSearchItems(newLocationSearchItems);
    navigate(browseURL(locations));
  };

  const selectItem = (item: TypeAheadItemType, add: boolean) => {
    resetStatus();

    if (item.type === "parcel") {
      setLocationSearchItems([]);
      navigate("/proformas/address/" + item.address_for_url?.replace(/ /g, "-") + "?source=lookup");
      return;
    }

    if (locationSearchItems.some((location: TypeAheadItemType) => location.id === item.id)) {
      return; // already added
    }

    const items = add ? [...locationSearchItems, item] : [item];
    updateLocationsAndNavigation(items);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        resetStatus();
        break;
      case "ArrowUp":
        e.preventDefault();
        if (typeaheadResult.length > 0 && selectedItem !== undefined && selectedItem > 0) {
          setSelectedItem(selectedItem - 1);
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        const nextIdx = selectedItem == null ? 0 : selectedItem + 1;
        if (typeaheadResult.length > 0 && nextIdx < typeaheadResult.length) {
          setSelectedItem(nextIdx);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (selectedItem != null) {
          selectItem(
            typeaheadResult[selectedItem],
            currentStateId === typeaheadResult[selectedItem].state_id,
          );
        }
        break;
    }
  };

  return (
    <div className="relative w-full text-base text-black" onKeyDown={handleKeyDown} ref={ref}>
      <div
        className="absolute top-0 z-[40] flex h-9 w-full items-center rounded-lg border border-gray-200 bg-white pl-2 text-sm font-light text-gray-500 sm:justify-between sm:pl-4"
        onClick={() => setShowInputPanel(true)}
      >
        Search an address or region...
        <div className="absolute right-3 top-2 z-[40]">
          <Icon path={mdiMagnify} className="size-5 text-gray-500" />
        </div>
      </div>
      {showInputPanel && (
        <>
          <input
            type="text"
            className={cn(
              "absolute top-0 z-[40] flex h-9 w-full items-center rounded-lg rounded-b-none border border-gray-200 bg-white pl-2 text-sm font-light text-gray-500 focus:border-gray-200 sm:justify-between sm:pl-4",
            )}
            value={inputText}
            onChange={onInputTextChange}
            autoComplete="off"
            placeholder="Search an address or region..."
            autoFocus
          />
          <button className="absolute right-3 top-2 z-[40]" onClick={() => resetStatus(true)}>
            {!loading && <Icon path={mdiClose} className="ml-1 size-5 text-gray-500" />}
            {loading && <Icon path={mdiAutorenew} className="ml-1 size-5 text-blue-600" spin />}
          </button>
          {typeaheadResult.length === 0 && (
            <div
              className={cn(
                "absolute top-[35px] z-[10000] h-96 max-h-[414px] w-full items-center overflow-y-scroll rounded-b-lg border border-gray-200 bg-white px-2",
                isMobile && "px-0",
              )}
            >
              <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>Recent Searches</div>
              {recentSearches?.slice(0, 3).map((search) => (
                <div className="p-1.5 pl-3 hover:bg-black hover:text-white" key={search.parameters?.url}>
                  <RecentSearchResultItem search={search} resetStatus={resetStatus} />
                </div>
              ))}
              <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>Saved Searches</div>
              {isLogged ? (
                <>
                  {savedSearches?.map((search: SaveSearchType) => (
                    <div className="p-1.5 pl-3 hover:bg-black hover:text-white" key={search.parameters?.url}>
                      <SavedSearchResultItem search={search} resetStatus={resetStatus} />
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-row p-2 pb-1 pl-3">
                  <span className="text-blue-700">
                    <WithSignIn useChildren>Log in</WithSignIn>
                  </span>
                  <span className="text-[#131618]">&nbsp;to see your saved searches.</span>
                </div>
              )}
            </div>
          )}
          {typeaheadResult.length > 0 && (
            <div
              className={cn(
                "absolute top-[35px] z-[10000] max-h-[414px] w-full items-center overflow-y-scroll rounded-b-lg border bg-white px-2",
                isMobile && "px-0",
              )}
            >
              {typeaheadResult.map((item, idx) => (
                <div key={item.id || item.parcel_id || idx}>
                  {item === firstNonParcelItem && (
                    <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>Regions</div>
                  )}
                  {item === firstParcelItem && (
                    <div className={cn("p-2 text-gray-400", isMobile ? "pb-1 pt-3" : "pt-4")}>Addresses</div>
                  )}
                  <SearchResultItem
                    item={item}
                    onSelection={selectItem}
                    selected={selectedItem === idx}
                    currentStateId={currentStateId}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
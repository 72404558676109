import { SaveProforma } from "components/proformas";
import { OptionsMenu, PellegoARV } from "components/proformas/header/";
import { isPellego } from "config";
import { formatPropertyName, getListSourceName } from "helpers";
import NumberFormat from "react-number-format";
import { useUserFromShareToken, useUserSession } from "state";
import { useFinancials, useProforma } from "state/proformas";

export const HeaderSticky = () => {
  const { proforma } = useProforma();
  const { financials } = useFinancials();
  const lastSoldEvent = proforma.parcel?.listing_history?.find((h: any) => h.event === "Sold");
  const hasMLS = !(proforma.has_mls === false);
  const showAddress = !(proforma.parcel?.most_recent_listing?.dont_show_address === true);
  const { arvEdited } = useUserFromShareToken(proforma);
  const currentUser = useUserSession();
  const isClientorAgent = currentUser.isClient || currentUser.isAgent;

  return (
    <div
      className={`${
        !hasMLS ? "top-12 pt-2 sm:top-6" : "top-0"
      } sticky z-40 flex w-full flex-col items-center justify-center border-b bg-white shadow-lg`}
    >
      {/* If !hasMLS then Sticky NotificationBar appears and we have tu change bottom padding */}
      <div
        className={`${
          !hasMLS ? "pt-0" : "pt-2"
        } flex w-full max-w-7xl items-center justify-between bg-white px-4 pb-2 xl:px-0`}
      >
        <div className="flex flex-col items-start pr-4 text-sm leading-5 xl:flex-row">
          {/* Property address */}
          {showAddress && (
            <div className="flex flex-row">
              <span className="font-medium">
                {proforma.name || formatPropertyName(proforma.parcel?._characteristics?.address) || "Unknown"}
              </span>
              <span className="text-sm font-medium text-gray-900">
                , {proforma.parcel?._characteristics?.city_for_address || proforma.parcel?.county + " County"}
                <span className="hidden lg:inline">
                  , {proforma.parcel?.state_obj?.abbreviation} {proforma.parcel?.zip_code}
                </span>
              </span>
            </div>
          )}
          {/* Listing source and number */}
          {proforma.listing?._on_market && proforma.listing_number && proforma.listing_number.length > 0 && (
            <div className="flex flex-row text-[13px] font-normal leading-5 xl:ml-1 xl:inline xl:text-sm">
              {showAddress ? <>(</> : <></>}
              {proforma.listing?.listing_source && (
                <span className="max-w-52 truncate sm:max-w-full lg:inline">
                  {getListSourceName(proforma.listing?.listing_source)}
                </span>
              )}
              <span className="ml-1">#{proforma.listing_number}</span>
              {showAddress ? <>)</> : <></>}
            </div>
          )}
        </div>
        <div>
          <div className="flex w-full items-center justify-end text-right align-top sm:align-middle">
            {/* Property save */}

            {proforma.listing?._on_market && (
              <div className="hidden w-full justify-end px-4 text-right text-sm text-gray-800 sm:inline sm:text-center">
                <div className="flex items-center whitespace-nowrap text-sm font-bold leading-5 sm:flex-col xl:flex-row">
                  <span className="order-2 font-light text-black xl:order-1 xl:pr-2">Active Listing</span>
                  <NumberFormat
                    className="order-1 xl:order-2"
                    value={proforma.parcel?.listing_history[0]?.price}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                    decimalScale={0}
                  />
                </div>
              </div>
            )}
            {!proforma.listing?._on_market && (
              <>
                {proforma.wholesale && (
                  <div className="hidden w-full justify-end px-4 text-right text-sm text-gray-800 sm:inline sm:text-center">
                    <div className="flex items-center whitespace-nowrap text-sm font-bold leading-5 sm:flex-col xl:flex-row">
                      <span className="order-2 font-light text-black xl:order-1 xl:pr-2">
                        Assignable Contract
                      </span>
                      <NumberFormat
                        className="order-1 xl:order-2"
                        value={proforma.wholesale?.price}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalScale={0}
                      />
                    </div>
                  </div>
                )}

                {proforma.parcel?.listing_history?.length > 0 ? (
                  <>
                    {proforma.parcel?.listing_history[0]?.event === "Pending" ? (
                      <div className="hidden w-full justify-end px-4 text-right text-sm text-gray-800 sm:inline sm:text-center">
                        <div className="flex items-center whitespace-nowrap text-sm font-bold leading-5 sm:flex-col xl:flex-row">
                          <span className="order-2 font-light text-black xl:order-1 xl:pr-2">Pending</span>
                          <NumberFormat
                            className="order-1 xl:order-2"
                            value={proforma.parcel?.listing_history[0]?.price}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="$"
                            decimalScale={0}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {lastSoldEvent ? (
                          <>
                            <div className="hidden w-full justify-end px-4 text-right text-sm text-gray-800 sm:inline sm:text-center">
                              <div className="flex items-center whitespace-nowrap text-sm font-bold leading-5 sm:flex-col xl:flex-row">
                                <span className="order-2 font-light text-black xl:order-1 xl:pr-2">
                                  {lastSoldEvent?.price
                                    ? "Last Sold for"
                                    : "Last Listed for"}
                                </span>
                                <NumberFormat
                                  className="order-1 xl:order-2"
                                  value={lastSoldEvent?.price || proforma.parcel?.listing_history.find((h: any) => h.event === "Listed")?.price}
                                  displayType="text"
                                  thousandSeparator={true}
                                  prefix="$"
                                  decimalScale={0}
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="hidden w-full justify-end px-4 text-right text-sm text-gray-800 sm:inline sm:text-center">
                            <div className="flex items-center whitespace-nowrap text-sm font-bold leading-5 sm:flex-col xl:flex-row">
                              <span className="order-2 font-light text-black xl:order-1 xl:pr-2">
                                Off Market
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="leading-1 hidden whitespace-nowrap pr-4 text-sm font-light sm:flex">
                    No sales on record
                  </div>
                )}
              </>
            )}
            {proforma.isGeoData &&
              (!proforma.parcel?.most_recent_listing?.no_avm_display || isClientorAgent) &&
              !arvEdited && (
                <div className="hidden w-full justify-end border-l pl-4 pr-6 text-right text-sm text-gray-800 sm:inline sm:text-center">
                  <div className="flex whitespace-nowrap text-sm font-bold leading-5 sm:flex-col xl:flex-row">
                    <span className="order-2 font-light text-black xl:order-1 xl:pr-2">
                      {isPellego ? "Pellego" : "Lotside"} ARV
                    </span>
                    <span className="order-1 xl:order-2">
                      <PellegoARV financials={financials} proforma={proforma} />
                    </span>
                  </div>
                </div>
              )}

            <SaveProforma
              text="SAVE"
              className={`mx-2 hidden items-center rounded-sm px-4 py-2 text-sm font-normal shadow-sm transition duration-150 ease-in-out md:inline-flex lg:h-9 xl:ml-0 ${
                proforma.is_mine
                  ? "bg-black text-white hover:bg-[#222222]"
                  : "border border-black bg-white text-black"
              }`}
            />
            {/* Property options menu */}
            <OptionsMenu openDir="left" />
          </div>
        </div>
      </div>
    </div>
  );
};

import { appConfig } from "config";

export const fetchBoxAppreciations = async (slat: number, elng: number, nlat: number, wlng: number) => {
  const box = `${slat}/${elng}/${nlat}/${wlng}`;
  return fetch(appConfig.apiEndpoint.boxAppreciation.replace(":slat_elng_nlat_wlng", box)).then((res) =>
    res.json(),
  );
};

const fetchLocationAppreciation = async (type: string, id: number) => {
  return fetch(
    appConfig.apiEndpoint.locationAppreciation.replace(":type", type).replace(":id", id.toString()),
  ).then((res) => res.json());
};

export const fetchLocationAppreciations = (locations: any[]) => {
  return Promise.all(locations.map((location) => fetchLocationAppreciation(location.type, location.id)));
};

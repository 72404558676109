import { PhotographIcon } from "@heroicons/react/outline";
import { ListedBy } from "components/ListedBy";
import { compModalShowIdxAtom, salesComphighlightHandlerAtom } from "components/proformas/comparables/";
import { StreetView } from "components/proformas/details";

import {
  buildCompUrl,
  formatDateShort,
  formatPropertyName,
  hasValue,
} from "helpers";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useUserSession } from "state";
import { EQUIVALENT_STRUCT_TYPES } from "state/browse/useFilters";
import { useProforma } from "state/proformas";
import { CompStatsType, SalesComparableType, useSalesComps } from "state/proformas/comparables";

export const computeOtherAdjustments = (comp: SalesComparableType, compStats: CompStatsType[]) => {
  let otherAdjustments = 0;
  Object.keys(comp.cost_diffs).forEach((cdKey) => {
    otherAdjustments += comp.cost_diffs[cdKey];
  });
  compStats.forEach((compStat) => {
    const key = compStat.compsKey || compStat.key;
    if (comp.cost_diffs[key]) {
      otherAdjustments -= comp.cost_diffs[key];
    }
  });
  return otherAdjustments;
};

type Props = {
  comparable: SalesComparableType;
  compStats: any[];
  totalScore: number;
  subjectProperty: any;
  isCompsViewAdjustmentEmpty: boolean;
};

export const CompPropertyCard = ({
  comparable,
  compStats,
  totalScore,
  subjectProperty,
  isCompsViewAdjustmentEmpty,
}: Props) => {
  const { proforma } = useProforma();
  const { toggleComparableStatus } = useSalesComps();
  const setCompModalShowIdx = useSetRecoilState(compModalShowIdxAtom);
  const otherAdjustments = computeOtherAdjustments(comparable, compStats);
  const highlightHandler = useRecoilValue(salesComphighlightHandlerAtom);
  const currentUser = useUserSession();
  const dontShowCompAddress = comparable?.parcel?.most_recent_listing?.dont_show_address || false;
  const isMissingThumbnail =
    !comparable.parcel?.thumbnail_url ||
    comparable.parcel?.thumbnail_url === "https://cdn.davinci.pellego.com/static/images/core/missing.png";

  var listingEvent = comparable.parcel?._characteristics.listing_status;
  var price = comparable.parcel?._characteristics.last_price;
  if (comparable.parcel?._characteristics.listing_status === "Active") {
    listingEvent = "Listed";
  }
  if (
    comparable.parcel &&
    comparable.parcel.most_recent_listing &&
    ["Austin Board of Realtors"].includes(comparable.parcel?.most_recent_listing?.listing_source) &&
    currentUser.hasAdvancedRole === false
  ) {
    listingEvent = "Last Price";
    price = comparable.parcel?._characteristics.last_list_price;
  }
  var flipPhotos = 0;
  if (comparable.parcel?.flip_listing?.photos?.length > 0) {
    flipPhotos = comparable.parcel?.flip_listing.photos.length;
  }
  const onMouseHover = (idx: number, highlighted: boolean) => {
    // Highlight property on Map
    if (highlightHandler) highlightHandler(idx, highlighted);
  };

  return (
    <div
      className="flex snap-start snap-normal flex-row sm:first:pl-4"
      onMouseEnter={() => onMouseHover(comparable._idx, true)}
      onMouseLeave={() => onMouseHover(comparable._idx, false)}
    >
      <div
        className={`w-44 min-w-44 whitespace-nowrap p-3 pr-0 sm:w-56 sm:min-w-56 sm:p-2 sm:py-4 ${
          !comparable.is_included ? "opacity-50" : ""
        }`}
      >
        <div className="flex h-[60px] flex-col items-start">
          <div className="text-sm font-bold">
            <input
              type="checkbox"
              className="mr-2 w-4 border text-[#70a08e] outline-0 indeterminate:bg-gray-300 focus:ring-0"
              onChange={() => toggleComparableStatus(comparable._idx)}
              checked={comparable.is_included}
            />
            Comp {String.fromCharCode("A".charCodeAt(0) + comparable._idx)}
            <span className="px-2 text-xs font-light text-gray-700">
              (<NumberFormat value={comparable.distance} displayType="text" decimalScale={2} /> miles)
            </span>
          </div>
          {dontShowCompAddress ? (
            <div className="ml-6 text-xs font-light text-blue-700">Address Hidden by Owner</div>
          ) : (
            <Link to={buildCompUrl(comparable)} target="_blank">
              <div className="ml-6 truncate text-xs font-light text-blue-700">
                {formatPropertyName(comparable.parcel?.street_address)}
              </div>
            </Link>
          )}
          <div className="ml-6 text-xs font-light text-gray-700">
            <ListedBy propertyParcel={comparable.parcel} shortSale={true}/>
          </div>
        </div>

        <LazyLoadComponent>
          {isMissingThumbnail ? (
            <div onClick={() => setCompModalShowIdx(comparable._idx)}>
              <StreetView
                otherClasses="flex cursor-pointer items-end rounded-sm bg-blue-300"
                sizeClasses="h-36 w-full"
                parcel={comparable.parcel}
                titles={false}
              />
            </div>
          ) : (
            <>
              <div
                className="bg-[background-size: 100% 100%;] relative flex h-36 w-full cursor-pointer items-end rounded-sm bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url('${comparable.parcel?.thumbnail_url}')`,
                }}
                onClick={() => setCompModalShowIdx(comparable._idx)}
              >
                {comparable.is_flip && (
                  <div className="absolute left-2 top-2 flex w-11/12">
                    <div className="flex items-center rounded-sm bg-black/40 px-1 py-0.5 text-xs text-white">
                      This is a Flip
                    </div>
                  </div>
                )}

                <div className="absolute bottom-2 right-2 z-10 flex w-11/12 items-center justify-between text-xs text-white">
                  <div className="flex items-center">
                    {comparable.is_flip && flipPhotos !== 0 && (
                      <>
                        <PhotographIcon className="mr-1 w-5" aria-hidden="true" />
                        {flipPhotos}
                      </>
                    )}
                  </div>
                  <div className="flex items-center">
                    <PhotographIcon className="mr-1 w-5" aria-hidden="true" />
                    {comparable.photo_count || comparable.parcel?.most_recent_listing?.photos?.length || 0}
                  </div>
                </div>
                <div className="absolute bottom-0 h-9 w-full bg-gradient-to-t from-gray-900/70 to-transparent" />
              </div>
            </>
          )}
        </LazyLoadComponent>

        <div
          className="mt-4 cursor-pointer text-xs leading-7"
          onClick={() => setCompModalShowIdx(comparable._idx)}
        >
          <div className="flex flex-row justify-items-stretch">
            {comparable.is_flip && comparable.parcel.flip_listing && (
              <div className="w-full text-left text-sm font-medium leading-4 text-gray-400">
                <NumberFormat
                  value={comparable.parcel.flip_listing.sale_price}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$ "
                  decimalScale={0}
                />
              </div>
            )}

            <div className="w-full text-right text-sm font-medium leading-4">
              <NumberFormat
                value={price}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>

          <div className="flex w-full text-xs font-normal leading-4 text-gray-400">
            {comparable.is_flip && comparable.parcel.flip_listing && (
              <div className="flex w-full basis-1/2 leading-4">
                <span className="hidden sm:inline">Bought&nbsp;</span>
                {formatDateShort(comparable.parcel.flip_listing.sale_date, false)}
              </div>
            )}
            <span className="flex w-full justify-end whitespace-normal text-right leading-4">
              {listingEvent}&nbsp;
              {formatDateShort(comparable.parcel._characteristics.last_date, false)}
            </span>
          </div>

          <div className="mt-2 text-right text-xs font-medium text-gray-700">Appreciation</div>
          <div className="flex flex-row justify-items-stretch">
            <div className="w-full text-right text-xs font-medium leading-7">
              {comparable.cost_diffs.market_change ? (
                <NumberFormat
                  value={comparable.cost_diffs.market_change}
                  displayType="text"
                  thousandSeparator={true}
                  prefix="$ "
                  decimalScale={0}
                />
              ) : (
                <>-</>
              )}
            </div>
          </div>

          <div className="flex flex-row justify-items-stretch py-0">
            <div className="mt-2 w-1/2 text-left text-xs font-bold text-gray-700">Comp</div>
            <div className="mt-2 w-1/2 text-right text-xs font-bold text-gray-700">Adjustments</div>
          </div>

          {compStats.map((stat) =>
            (stat.key === "hoa_dues" &&
              (stat.subjectValue === "$0" || stat.subjectValue === 0 || stat.subjectValue === null)) ||
            (stat.key === "structure_style" &&
              !EQUIVALENT_STRUCT_TYPES.includes(proforma.parcel._characteristics.structure_type)) ||
            (stat.key === "view_type" && isCompsViewAdjustmentEmpty) ? null : (
              <div
                key={stat.key}
                className="flex flex-row justify-items-stretch gap-2 border-b border-gray-200 py-0"
              >
                <div
                  className={`w-1/2 overflow-hidden text-ellipsis whitespace-nowrap ${
                    !hasValue(comparable.parcel._characteristics[stat.key])
                      ? "text-[0.6rem] italic text-gray-300"
                      : ""
                  }`}
                >
                  {hasValue(comparable.parcel._characteristics[stat.key]) ? (
                    <>
                      {stat.format === "currency" && (
                        <NumberFormat
                          value={comparable.parcel._characteristics[stat.key]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$ "
                          decimalScale={0}
                        />
                      )}
                      {stat.format === "number" && (
                        <NumberFormat
                          value={comparable.parcel._characteristics[stat.key]}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={comparable.parcel._characteristics[stat.key] % 1 !== 0 ? 2 : 0}
                        />
                      )}
                      {stat.format !== "number" && stat.format !== "currency" && (
                        <>{comparable.parcel._characteristics[stat.key]}</>
                      )}
                    </>
                  ) : (
                    <>{stat.fallback}</>
                  )}
                </div>

                <div className="w-1/2 text-right">
                  {comparable.cost_diffs[stat.compsKey ? stat.compsKey : stat.key] ? (
                    <NumberFormat
                      className="font-bold"
                      value={comparable.cost_diffs[stat.compsKey ? stat.compsKey : stat.key]}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
            ),
          )}

          <div className="mt-2 text-right text-xs font-medium text-gray-700">Other Adjustments</div>
          <div className="flex flex-row justify-items-stretch">
            <div className="w-full cursor-pointer text-right font-bold leading-4 text-blue-700">
              <NumberFormat
                value={otherAdjustments}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
          <div className="mt-2 text-right text-xs font-medium text-gray-700">Adjusted Value</div>
          <div className="flex flex-row justify-items-stretch">
            <div className="w-full text-right text-sm font-medium leading-4">
              <NumberFormat
                className="font-bold"
                value={comparable.projected_arv}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import NumberFormat from "react-number-format";
import { buildCompDiffs } from "state/proformas/comparables";

type Props = {
  costDiffs: any;
  compChars: any;
  subjectChars: any;
};

export const AdjustedValues = ({ costDiffs, compChars, subjectChars }: Props) => {
  const comparableDiffs = buildCompDiffs(costDiffs, subjectChars, compChars);

  return (
    <div className="flex w-full flex-row text-xs leading-6">
      <div className="flex basis-2/5 flex-col text-xs leading-6">
        {comparableDiffs.map((diff) => (
          <div
            key={`${diff.name}-a`}
            className="w-full max-w-32 overflow-hidden text-ellipsis whitespace-nowrap border-b border-gray-200 py-1 pr-2 text-left text-xs font-bold capitalize text-gray-700 sm:max-w-48"
          >
            {diff.name}
          </div>
        ))}
      </div>
      <div className="flex basis-1/5 flex-col text-xs leading-6">
        {comparableDiffs.map((diff) => (
          <div
            key={`${diff.name}-b`}
            className="border-b border-gray-200 py-1 pr-2 text-left text-xs text-gray-700"
          >
            {diff.key === "number_of_floors_in_building" ? subjectChars?.floors : <>&nbsp;</>}
          </div>
        ))}
      </div>

      <div className="flex basis-1/5 flex-col text-right text-xs leading-6 text-gray-700">
        {comparableDiffs.map((diff) => (
          <div
            key={`${diff.name}-c`}
            className="flex w-full flex-row justify-items-stretch gap-2 border-b border-gray-200 py-1"
          >
            <div className="whitespace-nowrap text-xs capitalize">
              {diff.key === "number_of_floors_in_building" ? (
                <>
                  {compChars?.floors}&nbsp;&nbsp;({diff.delta})
                </>
              ) : (
                <>
                  {diff.delta} {diff.pct}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex basis-1/5 flex-col text-right text-xs leading-6 text-gray-700">
        {comparableDiffs.map((diff) => (
          <div
            key={`${diff.name}-d`}
            className="flex w-full flex-row justify-items-stretch gap-2 border-b border-gray-200 py-1 text-right"
          >
            <div className="w-full whitespace-nowrap text-right text-xs font-bold text-gray-900">
              <NumberFormat
                value={diff.amount}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import { isPellego, isStage } from "config";
import { logosFromState } from "helpers";
import { useCurrentStates } from "state";

export const LoadingLogo = () => {
  const { currentStates } = useCurrentStates();
  const { smallLogoUrl } = logosFromState(currentStates);
  return (
    <div className="flex h-screen w-full flex-row items-center justify-center">
      <img
        className="h-16 w-auto animate-pulse opacity-0"
        src={smallLogoUrl}
        alt={isPellego && !isStage ? "Pellego" : "Lotside"}
      />
    </div>
  );
};

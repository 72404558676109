import { isPellego, isStage } from "config";
import { cn, logosFromState } from "helpers";
import { Link, useLocation } from "react-router-dom";
import { useCurrentStates } from "state";

export const Logo = () => {
  const location = useLocation();
  const { currentStates } = useCurrentStates();
  const { smallLogoUrl, logoUrl } = logosFromState(currentStates);
  const isPropertyPage = location.pathname.includes("/proformas/");

  return (
    <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
      <div className={cn("flex w-10 flex-shrink-0 items-center sm:hidden", isPropertyPage && "w-9")}>
        <Link to="/">
          <img
            className="h-7 w-auto"
            src={smallLogoUrl}
            alt={isPellego && !isStage ? "Pellego" : "Lotside"}
          />{" "}
        </Link>
      </div>
      <div
        className={cn(isPellego && !isStage ? "w-36" : "w-20", "hidden flex-shrink-0 items-center sm:flex")}
      >
        <Link to="/">
          <img className="h-7 w-auto" src={logoUrl} alt={isPellego && !isStage ? "Pellego" : "Lotside"} />
        </Link>
      </div>
    </div>
  );
};

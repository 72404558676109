import { Photos } from "components/proformas/financials/rehabtab/";
import { PhotosModalDesktop } from "components/proformas/modals";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useProforma } from "state/proformas";

export const RehabPropertyPhotos = () => {
  const { proforma } = useProforma();
  const [showPhotosModal, setShowPhotosModal] = useState<number | null>(null);

  const maxNumPhotos = 20;

  if (proforma.photos.length === 0) {
    return (
      <div className="absolute whitespace-nowrap rounded-lg border border-white bg-black/30 px-4 py-2 text-center text-xs text-white shadow-sm">
        No photos available
      </div>
    );
  }

  if (isMobile) {
    return <Photos />;
  }

  return (
    <div className="flex flex-wrap">
      {showPhotosModal !== null && (
        <PhotosModalDesktop
          photos={proforma.photos}
          isOpen={showPhotosModal !== null}
          closeModal={() => setShowPhotosModal(null)}
          showIdx={showPhotosModal}
        />
      )}

      <div className="flex h-64 flex-wrap justify-center gap-0.5 overflow-scroll sm:h-full sm:gap-2">
        {proforma.photos.slice(0, maxNumPhotos).map((photo, idx) => {
          return (
            <div
              className="relative h-64 cursor-pointer overflow-hidden rounded-sm sm:h-32 sm:basis-48"
              key={photo._id}
            >
              <LazyLoadImage
                className="max-w-full"
                src={photo.photo_url}
                alt={`Property Photo ${idx + 1}`}
                onClick={() => setShowPhotosModal(idx)}
              />

              {idx === maxNumPhotos - 1 && proforma.photos.length > maxNumPhotos && (
                <div
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer whitespace-nowrap rounded-lg border border-white bg-black/30 px-4 py-2 text-center text-xs text-white shadow-sm hover:bg-blue-400 hover:text-white"
                  onClick={() => setShowPhotosModal(idx)}
                >
                  View All Photos
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const fetcherWithCredentials = (
  urlOrArray: any,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  body: any = undefined
) => {
  const requestOptions: RequestInit = { method };
  requestOptions.credentials = "include";
  if (body) {
    requestOptions.headers = { "Content-Type": "application/json" };
    requestOptions.body = JSON.stringify(body);
  }
  return fetch(Array.isArray(urlOrArray) ? urlOrArray[0] : urlOrArray, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((res) => res.json());
};

export const fetcher = (url: string) => fetch(url)
  .then((res) => res.json());

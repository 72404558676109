import { isPellego } from "config";
import { useEffect } from "react";
import { useCurrentStates, useSiteConfig } from "state";

export const useDomainSwitcher = () => {
  const siteConfig = useSiteConfig();
  const { currentStates } = useCurrentStates();

  const isPellegoState = currentStates.some((state) => siteConfig.pellego_states?.includes(state));

  let domainToSwitchTo: string | null = null;

  if (window.location.hostname.includes("pellego.com") || window.location.hostname.includes("lotside.com")) {
    if (currentStates.length > 0 && siteConfig.pellego_states && siteConfig.pellego_states.length > 0) {
      if (isPellegoState) {
        if (!isPellego) {
          domainToSwitchTo = window.location.hostname.replace("lotside.com", "pellego.com");
        }
      } else {
        if (isPellego) {
          domainToSwitchTo = window.location.hostname.replace("pellego.com", "lotside.com");
        }
      }
    }
  }

  useEffect(() => {
    if (domainToSwitchTo) {
      window.location.hostname = domainToSwitchTo;
    }
  }, [siteConfig, currentStates, domainToSwitchTo]);
};

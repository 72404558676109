import { ExternalLinkIcon } from "@heroicons/react/outline";
import { ShowMore, SimpleMap } from "components/";
import { PrivateRemarks } from "components/proformas";
import NumberFormat from "react-number-format";
import { useUserSession } from "state";
import { useProforma } from "state/proformas";


type Props = {
  withPrivateRemarks: boolean;
};

export const PropertyRemarks = ({ withPrivateRemarks }: Props = { withPrivateRemarks: false }) => {
  const currentUser = useUserSession();
  const { proforma } = useProforma();

  const isData =
    proforma.parcel?.most_recent_listing?.listing_remarks ||
    proforma.parcel?.monthly_rent > 0 ||
    (withPrivateRemarks && currentUser.isLogged && proforma.parcel?.most_recent_listing?.agent_listing_remarks) ||
    (proforma.parcel?.most_recent_listing?.virtual_tour_url &&
      (proforma.parcel?.most_recent_listing?.listing_status === "Active" ||
        proforma.parcel?.most_recent_listing?.listing_status === "Pending"));

  if (!isData) return null;
        
  return (
    <div className="w-full max-w-7xl justify-center gap-4 px-4 pb-4 sm:rounded-md xl:px-0 xl:pb-6">
      {proforma.parcel.most_recent_listing?.listing_remarks &&
        (proforma.listing?._on_market ? (
          <div className="mt-2 flex items-center text-xs font-bold uppercase text-green-900">
            Listing remarks
          </div>
        ) : (
          <div className="mt-2 flex items-center text-xs font-bold uppercase text-green-900">
            Listing Remarks (Historical)
          </div>
        ))}

      {proforma.parcel.monthly_rent !== 0 && proforma.parcel.monthly_rent > 0 && (
        <div className="mt-1 text-sm">
          <span>Current Rent: </span>
          <NumberFormat
            value={proforma.parcel.monthly_rent}
            displayType="text"
            prefix="$"
            thousandSeparator={true}
          />
        </div>
      )}
      {proforma.parcel.most_recent_listing?.listing_remarks && (
        <ShowMore clampLines={4} className="text-md mt-2 text-left font-light leading-6 text-gray-700">
          {proforma.parcel.most_recent_listing?.listing_remarks}
        </ShowMore>
      )}

      {/* Only show if not Off Market & No Listing Remarks are available */}
      {!(!proforma.listing?._on_market && !proforma.parcel.most_recent_listing?.listing_remarks) &&
        !proforma.parcel.most_recent_listing?.listing_remarks &&
        proforma.photos.length === 0 && (
          <div className="-mt-3 h-80 w-full">
            <SimpleMap
              lat={proforma.parcel.latitude}
              lng={proforma.parcel.longitude}
              showMarker={!proforma.parcel.most_recent_listing?.dont_show_map_link}
            />
          </div>
        )}

      {withPrivateRemarks && currentUser.isLogged && <PrivateRemarks />}

      {proforma.parcel.most_recent_listing?.virtual_tour_url &&
        (proforma.parcel.most_recent_listing?.listing_status === "Active" ||
          proforma.parcel.most_recent_listing?.listing_status === "Pending") && (
          <a
            href={proforma.parcel.most_recent_listing?.virtual_tour_url}
            className="mt-4 flex rounded-sm text-sm text-blue-700"
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon className="mr-2 h-5 w-5 text-blue-500" /> View Virtual Tour
          </a>
        )}
    </div>
  );
};
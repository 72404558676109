import { Menu } from "@headlessui/react";
import { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import ReactSlider from "react-slider";
import { useRecoilValue } from "recoil";
import { filteredSearchResultsSelector } from "state/browse";

const priceBuckets = [
  -Infinity,
  50_000,
  75_000,
  100_000,
  125_000,
  150_000,
  175_000,
  200_000,
  225_000,
  250_000,
  275_000,
  300_000,
  325_000,
  350_000,
  375_000,
  400_000,
  425_000,
  450_000,
  475_000,
  500_000,
  550_000,
  600_000,
  650_000,
  700_000,
  750_000,
  800_000,
  850_000,
  900_000,
  950_000,
  1_000_000,
  1_250_000,
  1_500_000,
  1_750_000,
  2_000_000,
  2_250_000,
  2_500_000,
  2_750_000,
  3_000_000,
  3_250_000,
  3_500_000,
  3_750_000,
  4_000_000,
  4_250_000,
  4_500_000,
  4_750_000,
  5_000_000,
  6_000_000,
  7_000_000,
  8_000_000,
  9_000_000,
  10_000_000,
  Infinity,
];

const propertiesPerPriceBucket = (properties: any[]) => {
  const numPropertiesPerBucket: Record<number, number> = {};
  const allPriceBuckets: Array<[number, number]> = [];
  let maxVal = 0;

  properties.forEach((property) => {
    const price = property.listingPrice;
    const bucket = priceBuckets.find((bucket) => price < bucket);
    if (bucket) {
      numPropertiesPerBucket[bucket] = numPropertiesPerBucket[bucket]
        ? numPropertiesPerBucket[bucket] + 1
        : 1;
      maxVal = Math.max(maxVal, numPropertiesPerBucket[bucket]);
    }
  });

  priceBuckets.forEach((bucket) => {
    allPriceBuckets.push([bucket, (numPropertiesPerBucket[bucket] || 0) / maxVal]);
  });

  return allPriceBuckets;
};

type Props = {
  priceMin: number | null;
  priceMax: number | null;
  priceRangeSet: (min: number | null, max: number | null) => void;
  autoApply: boolean;
};

export const PropertiesPriceSlider = ({ priceMin, priceMax, priceRangeSet, autoApply }: Props) => {
  const searchResult = useRecoilValue(filteredSearchResultsSelector);
  const propertiesPerPrice = useMemo(
    () => propertiesPerPriceBucket(searchResult),
    [searchResult],
  );
  const [min, setMin] = useState<number | null>(priceMin);
  const [max, setMax] = useState<number | null>(priceMax);

  const minSliderIndex = min ? priceBuckets.findIndex((bucket) => min <= bucket) : 0;
  let maxSliderIndex = max ? priceBuckets.findIndex((bucket) => max <= bucket) : priceBuckets.length - 1;
  if (minSliderIndex && maxSliderIndex && minSliderIndex > maxSliderIndex) {
    maxSliderIndex = minSliderIndex;
  }

  const onSliderChange = (values: number[]) => {
    if (values[0] > 0) {
      setMin(priceBuckets[values[0]]);
    } else {
      setMin(null);
    }
    if (values[1] < priceBuckets.length - 1) {
      setMax(priceBuckets[values[1]]);
    } else {
      setMax(null);
    }
  };

  const onAfterSliderChange = (values: number[]) => {
    if (autoApply) {
      priceRangeSet(min, max);
    }
  };

  useEffect(() => {
    setMin(priceMin);
    setMax(priceMax);
  }, [priceMin, priceMax]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (autoApply) {
      timer = setTimeout(() => {
        priceRangeSet(min, max);
        timer = null;
      }, 600);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  return (
    <div>
      <div className="mt-2 flex w-full flex-row justify-items-stretch gap-4">
        <div className="flex w-full px-2 text-sm font-bold text-gray-700">List Price</div>
      </div>
      <div className="m-2 flex h-32 flex-row items-end border">
        {propertiesPerPrice.map(([bucket, value]) => (
          <div
            key={bucket}
            className={`mr-0.5 w-2 ${
              (min && bucket < min) || (max && max < bucket) ? "bg-gray-200" : "bg-black"
            }`}
            style={{ height: `${value * 90}%` }}
          />
        ))}
      </div>
      <ReactSlider
        className="mt-3 w-full"
        thumbClassName="bg-white mt-1 w-4 h-4 rounded-full border-2 border-gray-500 cursor-pointer"
        trackClassName="m-2 bg-gray-200 h-2 rounded-full"
        value={[minSliderIndex, maxSliderIndex]}
        max={priceBuckets.length - 1}
        pearling
        minDistance={2}
        onChange={onSliderChange}
        onAfterChange={onAfterSliderChange}
      />

      <div className="mt-12 flex w-full flex-row justify-items-stretch gap-4 px-2">
        <div className="flex w-full flex-row gap-4">
          <NumberFormat
            className="h-5 w-1/2 border-gray-300 p-4 text-right text-sm"
            value={min || ""}
            placeholder="Enter Min"
            prefix="$ "
            thousandSeparator={true}
            decimalScale={0}
            displayType="input"
            onValueChange={(values) => {
              if (min !== values.floatValue) {
                setMin(values.floatValue as number | null);
              }
            }}
          />

          <NumberFormat
            className="h-5 w-1/2 border-gray-300 p-4 text-right text-sm"
            value={max || ""}
            placeholder="Enter Max"
            prefix="$ "
            thousandSeparator={true}
            decimalScale={0}
            displayType="input"
            onValueChange={(values) => {
              if (max !== values.floatValue) {
                setMax(values.floatValue as number | null);
              }
            }}
          />
        </div>
      </div>

      {autoApply ? (
        <div className="mr-2 mt-8 flex flex-row-reverse gap-4" />
      ) : (
        <div className="mr-2 mt-8 flex flex-row-reverse gap-4">
          <Menu.Item>
            <button
              className="rounded-xs h-8 bg-[#6389c4] px-3 text-sm text-white hover:bg-[#4674b9]"
              onClick={() => {
                priceRangeSet(min, max);
              }}
            >
              Apply
            </button>
          </Menu.Item>
          <Menu.Item>
            <button
              className="rounded-xs h-8 px-3 text-sm text-gray-500 hover:bg-gray-200"
              onClick={() => {
                setMin(null);
                setMax(null);
                priceRangeSet(null, null);
              }}
            >
              Reset
            </button>
          </Menu.Item>
        </div>
      )}
    </div>
  );
};

import { forwardRef, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useUserFromShareToken, useUserSession } from "state";
import {
  calculatePurchaseFromProfit,
  calculateROIs,
  getFinancialsForProfit,
  // useCustomStructure,
  useFinancials,
  useProforma,
} from "state/proformas";

type Props = {
  setSelectedTab: CallableFunction;
};

export const FlipCard = forwardRef(({ setSelectedTab }: Props, carryingTimeRef) => {
  const { financials, setFinancials, updateCalcSheet, setResaleValue } = useFinancials();
  const { proforma } = useProforma();
  const [flipProfit, setFlipProfit] = useState(financials?.carry.profit);
  const [carryProfit, setCarryProfit] = useState(financials?.carry.profit);
  const [purchasePrice, setPurchasePrice] = useState(financials?.calcsheet.purchase_price);
  const { sharingUser, shareDate } = useUserFromShareToken(proforma);
  // const { hasCustomStructure } = useCustomStructure(proforma);
  const currentUser = useUserSession();
  const notClientNorAgent = !currentUser.isClient && !currentUser.isAgent;

  useEffect(() => {
    if (carryProfit !== financials?.carry.profit) {
      setFlipProfit(financials?.carry.profit);
      setCarryProfit(financials?.carry.profit);
    }
  }, [carryProfit, financials?.carry.profit]);

  useEffect(() => {
    // if (hasCustomStructure()) {
    //   updateCalcSheet({ resale_value: financials?.calcsheet.pellego_arv });
    // }
    if (proforma.parcel.most_recent_listing?.no_avm_display && notClientNorAgent) {
      updateCalcSheet({ resale_value: 0 });
    }
    // updateCalcSheet({ rehab_costs_flip: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!financials) return null;

  const ROIs = calculateROIs(financials, financials?.calcsheet.purchase_price, financials?.carry.profit);

  const onCarryingTimeChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      updateCalcSheet({ carrying_time: values.floatValue });
    }
  };

  const onResaleValueChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      setResaleValue(values.floatValue);
    }
  };

  const onPurchasePriceBlur = (e: any) => {
    const val = Number(e.target.value.replace(/[^\d-]+/g, ""));
    if (val) {
      const pp = calculatePurchaseFromProfit(financials, undefined, val);
      if (pp.target_profit) {
        updateCalcSheet({ purchase_price: val });
        setFlipProfit(pp.target_profit);
        setPurchasePrice(val);
      }
    }
  };

  const onFlipProfitChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      setFlipProfit(values.floatValue);
    }
  };

  const onFlipProfitBlur = (e: any) => {
    const strVal = e.target.value.replace(/[^\d-]+/g, "");
    const val = Number(strVal);
    if (val || strVal === "0") {
      const newFinancials = getFinancialsForProfit(financials, val);
      setFinancials(newFinancials);
      setPurchasePrice(newFinancials?.calcsheet.purchase_price);
    }
  };

  const onRehabCostsChange = (values: any, evInfo: any) => {
    if (evInfo.source === "event" && (values.floatValue || values.floatValue === 0)) {
      updateCalcSheet({ rehab_costs_flip: values.floatValue });
    }
  };

  return (
    <div className="rounded-lg border border-gray-300 bg-gray-50 p-6 shadow-lg">
      {/* card content */}

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap font-bold">After Repair Value</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0"
            value={financials?.calcsheet.resale_value}
            thousandSeparator={true}
            prefix="$ "
            decimalScale={0}
            onValueChange={onResaleValueChange}
          />
        </div>
      </div>

      <div className="mb-3 border-b pb-4"></div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap">Carrying Months</div>
        <div className="w-full basis-2/5">
          <div className="w-full text-center">
            <NumberFormat
              getInputRef={carryingTimeRef}
              className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0"
              value={financials?.calcsheet.carrying_time}
              thousandSeparator={false}
              decimalScale={0}
              onValueChange={onCarryingTimeChange}
            />
          </div>
        </div>
      </div>

      <div className="pb-1 pt-4 text-xs font-bold">Less Estimate Costs:</div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap">Purchase Price</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0"
            value={purchasePrice}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
            onBlur={onPurchasePriceBlur}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                onPurchasePriceBlur(e);
              }
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div
          className="w-full basis-3/5 cursor-pointer whitespace-nowrap text-blue-700"
          onClick={() => setSelectedTab(0)}
        >
          Rehab Costs
        </div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0"
            value={financials.calcsheet.rehab_costs_flip}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
            onValueChange={onRehabCostsChange}
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap">Buying Costs</div>
        <div className="w-full basis-2/5 pr-1 text-right">
          <NumberFormat
            displayType="text"
            value={financials?.costgroupTotals.acquisition}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap">Carrying Costs</div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            displayType="text"
            value={financials?.costgroupTotals.carrying}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 pb-4 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap">Selling Costs</div>
        <div className="w-full basis-2/5 pr-1 text-right">
          <NumberFormat
            displayType="text"
            value={financials?.costgroupTotals.selling}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
          />
        </div>
      </div>

      <div className="flex flex-row justify-items-stretch gap-2 border-t pt-4 text-sm leading-9">
        <div className="w-full basis-3/5 whitespace-nowrap font-bold">Resell Profit</div>
        <div className="w-full basis-2/5">
          <NumberFormat
            className="h-5 w-full border border-[#E4C442] bg-[#F6EDC6] p-3 text-right text-sm outline-0 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0"
            value={flipProfit}
            thousandSeparator={true}
            decimalScale={0}
            prefix="$ "
            onValueChange={onFlipProfitChange}
            onBlur={onFlipProfitBlur}
            onKeyUp={(e: React.KeyboardEvent) => {
              if (e.key === "Enter") {
                onFlipProfitBlur(e);
              }
            }}
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 pt-2 text-sm">
        <div className="w-full basis-3/5 whitespace-nowrap">
          <div className="font-bold">Total ROI</div>
        </div>
        <div className="w-full pr-1 text-right" tabIndex={0}>
          <NumberFormat
            value={ROIs.totalROI * 100}
            displayType="text"
            thousandSeparator={true}
            suffix="%"
            decimalScale={2}
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 pt-2 text-sm">
        <div className="w-full basis-3/5 whitespace-nowrap">
          <div className="font-bold">Cash In</div>
        </div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            value={financials?.carry.cashin}
            displayType="text"
            thousandSeparator={true}
            prefix="$ "
            decimalScale={0}
          />
        </div>
      </div>
      <div className="flex flex-row justify-items-stretch gap-2 pt-2 text-sm">
        <div className="w-full basis-3/5 whitespace-nowrap">
          <div className="font-bold">Cash ROI</div>
          <div className="text-xs text-gray-400">
            <div className="pt-5">ROIs are not annualized</div>
          </div>
        </div>
        <div className="w-full pr-1 text-right">
          <NumberFormat
            value={ROIs.cashROI * 100}
            displayType="text"
            thousandSeparator={true}
            suffix="%"
            decimalScale={2}
          />
        </div>
      </div>
      {sharingUser && (
        <div className="mt-4 text-center text-sm font-light text-black">
          Edited by {sharingUser.name_first} {sharingUser.name_last}
          {shareDate ? ` on ${shareDate}. ` : ". "}
        </div>
      )}
    </div>
  );
});

import { InformationCircleIcon } from "@heroicons/react/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { SimpleMap } from "components";
import { ArrowUturnLeftIcon } from "components/icons";
import { StreetView } from "components/proformas/details";
import { PhotosModalDesktop, PhotosModalMobile, SatelliteInfoModal } from "components/proformas/modals";
import { cn } from "helpers";
import { Fragment, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { useSignInSignUpModal } from "state";
import { useProforma } from "state/proformas";

export const setShowPhotosModalIdxAtom = atom<undefined | number>({
  key: "setShowPhotosModalIdxAtom",
  default: undefined,
});

export const Photos = () => {
  const { proforma } = useProforma();
  const location = useLocation();
  const { openSignInModal } = useSignInSignUpModal();
  const [isStreetView, setIsStreetView] = useState(false);
  const [showPhotosModalIdx, setShowPhotosModalIdx] = useRecoilState(setShowPhotosModalIdxAtom);
  const [showSatelliteInfoModal, setShowSatelliteInfoModal] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<number | null>(null);
  const photosLength = proforma.photos?.length;
  const isPhotos = photosLength !== 0;
  const photosCount = proforma.photo_count || 0;
  const allPhotos = proforma.parcel?.photos || [];

  const firstNonPublicPhoto = allPhotos.find(
    (photo: any) =>
      photo.description !== null &&
      photo.photo_url !== proforma.photos[proforma.photos?.length - 1]?.photo_url,
  );

  const showPhotosCount = photosCount > photosLength;

  const PhotosModalComponent = isMobile ? PhotosModalMobile : PhotosModalDesktop;

  const handleScroll = () => {
    setIsScrolling(true);
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }
    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 300);
  };

  const slideLeft = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft -= 480;
  };
  const slideRight = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft += 480;
  };

  const parcel = proforma.parcel;
  const geographyData: any = [];
  if (parcel.elevation != null) {
    geographyData.push({
      name: "Elevation",
      value: parcel.elevation + " Meters",
    });
  }

  if (parcel.slope != null) {
    geographyData.push({
      name: "Slope",
      value: parcel.slope + " Degrees",
    });
  }

  if (parcel.faces != null) {
    geographyData.push({
      name: "Property Direction",
      value: parcel.faces,
    });
  }

  if (parcel.closest_body_of_water != null) {
    geographyData.push({
      name: "Closest Body of Water",
      value: parcel.closest_body_of_water,
    });
  }

  if (parcel.closest_body_of_water != null) {
    geographyData.push({
      name: "Distance to Water",
      value: parcel.shortest_distance_to_water + " Meters",
    });
  }

  if (parcel.topological_position_index != null) {
    geographyData.push({
      name: "Topological Position Index",
      value: parcel.topological_position_index,
    });
  }

  return (
    <div className="group relative z-0 w-full overflow-auto bg-[#f7faff]">
      {/* Photo scroll controls */}
      {!isScrolling && photosLength > 1 && !isMobile && (
        <>
          {scrollContainerRef.current && scrollContainerRef.current.scrollLeft > 10 && (
            <ChevronLeftIcon
              className="absolute left-8 top-[calc(50%-1.25rem)] z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
              onClick={slideLeft}
            />
          )}
          {scrollContainerRef.current &&
            scrollContainerRef.current.scrollLeft <=
              scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth - 1 && (
              <ChevronRightIcon
                className="absolute right-8 top-[calc(50%-1.25rem)] z-10 size-10 cursor-pointer rounded-full bg-slate-500 text-white opacity-60"
                onClick={slideRight}
              />
            )}
        </>
      )}

      {/* Photo scroll container */}
      <div
        className="flex w-full snap-x snap-mandatory overflow-x-auto scroll-smooth"
        ref={scrollContainerRef}
        onScrollCapture={(e) => {
          photosLength > 1 && !isMobile && handleScroll();
        }}
      >
        {/* No property photos provided and no GeoData */}
        {!proforma.isGeoData && photosLength === 0 && (
          <div className="-ml-4 flex h-10 w-full flex-row items-center justify-center overflow-hidden bg-[#FFF2CC]">
            No property geographical data or photos provided
          </div>
        )}

        {/* Carthographic Map */}
        {!isMobile && proforma.isGeoData && (
          <div className="relative mx-2 block shrink-0 snap-start snap-normal">
            <div className="h-[15rem] w-[calc(15rem*1.15)] cursor-pointer object-cover font-medium sm:h-[24rem] sm:w-[calc(24rem*1.15)]">
              <SimpleMap
                lat={proforma.parcel.latitude}
                lng={proforma.parcel.longitude}
                showMarker={!proforma.parcel.most_recent_listing?.dont_show_map_link}
                rounded
                zoomPosition={isPhotos ? "bottomright" : "bottomleft"}
              />
            </div>
          </div>
        )}

        {/* Sattelite Map */}
        <div className={isStreetView ? "hidden" : "relative mx-2 block shrink-0 snap-start snap-normal"}>
          {proforma.isGeoData && (
            <div className="h-[15rem] w-[calc(15rem*1.15)] cursor-pointer bg-[#f7faff] object-cover font-medium sm:h-[24rem] sm:w-[calc(24rem*1.15)]">
              <SimpleMap
                lat={proforma.parcel.latitude}
                lng={proforma.parcel.longitude}
                zoomLevel={19}
                showMarker={!proforma.parcel.most_recent_listing?.dont_show_map_link}
                satellite
                rounded
                zoomPosition={isMobile ? "bottomleft" : isPhotos ? "bottomright" : "bottomleft"}
              />
            </div>
          )}
          {/* Sattelite Map controls */}
          {!isMobile && isPhotos && (
            <>
              <div
                className="absolute bottom-[26px] left-4 z-10 mx-auto flex w-fit cursor-pointer flex-row items-center border border-black bg-white p-1 px-2 text-[10px] font-bold"
                onClick={() => setIsStreetView(true)}
              >
                <ArrowUturnLeftIcon className="size-4 pr-1" />
                <div className="mt-[1px]">STREET VIEW</div>
              </div>
              <div
                className="absolute left-6 top-4 z-10 flex w-fit cursor-pointer flex-row"
                onClick={() => setShowSatelliteInfoModal(true)}
              >
                <InformationCircleIcon className="size-5 rounded-full bg-white" />
              </div>
            </>
          )}
        </div>

        {/* StreetView Map */}
        {proforma.isGeoData && (
          <div className="w-full">
            {(!isMobile || (isMobile && !isPhotos)) && (
              <div
                className={cn(
                  "hidden",
                  (isStreetView || !isPhotos) &&
                    "relative mx-2 block shrink-0 cursor-pointer snap-start snap-normal",
                  !isMobile &&
                    !isPhotos &&
                    "relative mx-2 block w-full shrink-0 cursor-pointer snap-start snap-normal",
                )}
              >
                <StreetView
                  otherClasses="bg-[#f7faff] object-cover font-medium"
                  parcel={proforma.parcel}
                  titles={!proforma.parcel.most_recent_listing?.dont_show_address}
                  sizeClasses={cn(
                    "w-[calc(15rem*1.15)] h-[15rem] sm:h-[24rem] sm:min-w-[calc(24rem*1.15)]",
                    !isMobile && !isPhotos && "w-full h-[15rem] sm:h-[24rem] sm:min-w-[calc(24rem*1.15)]",
                  )}
                  rounded="rounded"
                  load={isStreetView || !isPhotos}
                />
                {/* StreetView Map controls */}
                {!isMobile && isPhotos && (
                  <div
                    className="absolute bottom-[26px] left-4 z-10 mx-auto flex w-fit cursor-pointer flex-row items-center border border-black bg-white p-1 px-2 text-[10px] font-bold"
                    onClick={() => setIsStreetView(false)}
                  >
                    <ArrowUturnLeftIcon className="size-4 pr-1" />
                    <div className="mt-[1px]">SATELLITE VIEW</div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Property photos */}
        {proforma.photos.map((photo: any, idx: number) => (
          <Fragment key={photo._id}>
            <div
              // key={photo._id}
              className={`${photosLength === 1 && !showPhotosCount && "w-full"} shrink-0 snap-start snap-normal px-2`}
            >
              <LazyLoadImage
                className={`${
                  photosLength === 1 && !showPhotosCount && "w-full"
                } h-[15rem] min-w-[calc(15rem*1.15)] shrink-0 cursor-pointer rounded object-cover sm:h-[24rem] sm:min-w-[calc(24rem*1.15)]`}
                onClick={() => setShowPhotosModalIdx(idx)}
                src={photo.photo_url}
                alt={`Sale: ${photo.sale_id} Parcel: ${photo.parcel_id}`}
              />
            </div>
            {idx === photosLength - 1 && showPhotosCount && (
              <div className="relative shrink-0 snap-start snap-normal px-2">
                <LazyLoadImage
                  className="flex h-[15rem] w-[calc(15rem*1.15)] shrink-0 cursor-pointer rounded object-cover sm:h-[24rem] sm:min-w-[calc(24rem*1.15)]"
                  src={firstNonPublicPhoto?.photo_url}
                  alt={
                    firstNonPublicPhoto?.photo_url
                      ? `${photosCount - photosLength} photos are restricted from public view due to state or MLS regulations. Login with your agent account or client account.`
                      : ""
                  }
                />
                {/* Photo Count Info */}
                <div className="absolute inset-0 z-10 mx-2 flex h-60 min-w-[calc(15rem*1.15)] shrink-0 place-items-center rounded bg-black bg-opacity-85 sm:h-96">
                  <div className="mx-auto mt-[1px] flex flex-col rounded-sm bg-white p-5 py-6 text-center text-[12px]">
                    <div>
                      <span className="font-bold">{photosCount - photosLength} photos</span> are restricted
                    </div>
                    <div>from public view due to</div>
                    <div>state or MLS regulations</div>
                    <div>&nbsp;</div>
                    <div>
                      <Link
                        to="/login"
                        state={{ from: location }}
                        className="text-blue-700"
                        onClick={(e) => {
                          e.preventDefault();
                          openSignInModal();
                        }}
                      >
                        Login
                      </Link>
                      <span> with your agent</span>
                    </div>
                    <div>account or client account</div>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        ))}
        {showPhotosModalIdx !== undefined && (
          <PhotosModalComponent
            photos={proforma.photos}
            isOpen={showPhotosModalIdx !== undefined}
            closeModal={() => setShowPhotosModalIdx(undefined)}
            showIdx={showPhotosModalIdx}
          />
        )}
      </div>

      {showSatelliteInfoModal && (
        <SatelliteInfoModal
          isOpen={showSatelliteInfoModal}
          closeModal={() => setShowSatelliteInfoModal(false)}
          data={geographyData}
        />
      )}
    </div>
  );
};

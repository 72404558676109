export const Error = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-[url('https://cdn.davinci.pellego.com/static/images/home_bg.jpg')] bg-cover bg-no-repeat text-center">
      <div className="mb-8 font-light uppercase text-gray-800">OOPS! An unexpected error has occurred!</div>
      <div className="max-w-md text-lg font-extralight text-gray-600">
        This is an error that we have not seen before. Please try again later.
        <br />
        <br />
        <a href="/">Click here to return to the home page.</a>
      </div>
    </div>
  );
};

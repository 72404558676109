import {
  LongTermAssumption,
  RentalCard,
  RentalCashIn,
  RentalCostGroup,
  RentalGraphs,
} from "components/proformas/financials/rentaltab/";
import { getUnitSections } from "components/proformas/PropertyDetails";
import { formatCurrency } from "helpers";
import { useProforma } from "state/proformas";
import { isPellego } from "config";

export const RentalTab = () => {
  const { proforma } = useProforma();
  const assessValues = proforma.parcel?.values;
  const lastAssess =
    assessValues.length > 0
      ? assessValues.sort((a: any, b: any) => b.assessment_year - a.assessment_year)[0]
      : null;
  const unitSections = getUnitSections(proforma).length;

  return (
    <div className="basis-3/3 w-full">
      <div className="text-sm sm:text-base mb-6 text-center text-gray-500 sm:mb-10">
        Edit the income and long term assumptions in yellow boxes. Pre-set assumptions are computer generated
        by {isPellego ? "Pellego" : "Lotside"}.
      </div>
      <div className="flex w-full flex-col gap-2 sm:flex-row sm:gap-8">
        <div className="flex w-full flex-col justify-between gap-2 sm:w-1/2 sm:gap-8 lg:w-2/3 lg:flex-row">
          <div className="w-full sm:pr-4 lg:w-1/2">
            <RentalCashIn />
          </div>
          <div className="w-full sm:pr-4 lg:w-1/2">
            <RentalCostGroup costGroupName="Expenses" />
            <RentalCostGroup costGroupName="Budgets" />
          </div>
        </div>
        <div className="order-first mb-8 mt-4 w-full sm:order-last sm:mt-0 sm:w-1/2 lg:w-1/3">
          <RentalCard />
          {lastAssess !== null && (
            <div className="flex flex-col gap-2 text-pretty text-center pl-1 pt-4 text-xs leading-5 text-gray-400">
              {unitSections < 5 ? (
                <div>
                  {`The ${lastAssess.assessment_year} assessed structure value is 
              ${formatCurrency(lastAssess.assessed_structure_value, 0)}, suggesting a standard residential tax
              depreciation of ${formatCurrency(lastAssess.assessed_structure_value / 330, 0)} per month.`}
                </div>
              ) : (
                <div>
                  {`The ${lastAssess.assessment_year} assessed structure value is ${formatCurrency(lastAssess.assessed_structure_value, 0)}, suggesting a standard commercial tax
              depreciation of ${formatCurrency(lastAssess.assessed_structure_value / 468, 0)} per month.`}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="mt-10 flex w-full flex-col gap-2 border-t pt-6 sm:flex-row sm:gap-8 sm:pt-10">
        <div className="basis-1/1 flex w-full flex-col pb-4 sm:basis-1/3">
          <div className="pt-4 text-xl font-medium">Long Term Assumption</div>
          <LongTermAssumption />
        </div>

        <div className="basis-1/1 w-full sm:basis-2/3">
          <RentalGraphs />
        </div>
      </div>
    </div>
  );
};

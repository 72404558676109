import { yupResolver } from "@hookform/resolvers/yup";
import { useUserActions } from "actions";
import { Alert, FormFieldError } from "components";
import { Checkbox, LabeledInput, SubmitButton } from "components/forms";
import { isPellego } from "config";
import { useEffect, useState } from "react";
import { useForm, UseFormProps } from "react-hook-form";
import { Link } from "react-router-dom";
import { useSignInSignUpModal } from "state";
import * as Yup from "yup";

type Props = {
  asModal?: boolean;
  nonMLS?: boolean;
};

type Questionaire = {
  license: Array<string>;
};

export const SignUp = ({ asModal, nonMLS }: Props) => {
  const userActions = useUserActions();
  const { openSignInModal } = useSignInSignUpModal();
  let [isSignupSuccessful, setIsSignupSuccessful] = useState(false);

  // form validation rules
  const validationSchema = Yup.object().shape({
    name_first: Yup.string()
      .required("First name is required.")
      .max(32, "First name must be 32 characters max."),
    name_last: Yup.string()
      .required("Last name is required.")
      .max(32, "Last name must be no more than 32 characters."),
    email: Yup.string().email("Invalid email address").required("Email is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password must be at least 6 characters.")
      .max(255, "Password must be no more than 255 characters."),
    confirmPassword: Yup.string()
      .required("Confirm Password is required.")
      .min(6, "Password must be at least 6 characters.")
      .max(255, "Password must be no more than 255 characters.")
      .oneOf([Yup.ref("password")], "Passwords must match."),
    license: Yup.string(),
    acceptTerms: Yup.bool().oneOf(
      [true],
      `You must accept the ${isPellego ? "Pellego" : "Lotside"} terms to register.`,
    ),
  });
  const formOptions: UseFormProps = { mode: "all", resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (!isSignupSuccessful) {
      setFocus("name_first");
    }
  }, [setFocus, isSignupSuccessful]);

  const onSubmit = (userData: any) => {
    const questionaire: Questionaire = {
      license: userData.license === "false" ? [] : [userData.license],
    };
    const newUserData: any = Object.fromEntries(
      Object.entries(userData).filter(([key]) => key !== "license"),
    );
    const userDataWithQuestionaire = { ...newUserData, questionaire };

    return userActions
      .register(userDataWithQuestionaire)
      .then(() => {
        setIsSignupSuccessful(true);
      })
      .catch((err) => {
        console.log("Register error:", err, userData);
      });
  };

  return (
    <div className="mx-auto mt-8 max-w-2xl px-8 pb-4">
      {!isSignupSuccessful && (
        <div className="mb-4 text-sm">
          Already have an account?{" "}
          <Link
            to="/login"
            className="text-blue-700"
            onClick={(e) => {
              if (asModal) {
                e.preventDefault();
                openSignInModal();
              }
            }}
          >
            Sign&nbsp;In
          </Link>
        </div>
      )}
      <h3 className="text-3xl">{nonMLS ? "Stay up to date" : "Create a free account"}</h3>
      {!isSignupSuccessful && (
        <h4 className="text-md mt-2">
          {nonMLS && "Create a free account and we'll email you when we get MLS data for this region"}
        </h4>
      )}
      <div className="">
        {isSignupSuccessful ? (
          <div className="mt-2 font-medium">
            <p className="mt-2">
              Thank you! Please check your email for confirmation to validate your account.
            </p>
            <p className="mt-2">
              If you do not see an email within a few minutes, please check your spam filters.
            </p>
          </div>
        ) : (
          <>
            <Alert />

            <div className="grid grid-cols-1 gap-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <section className="block">
                  <LabeledInput
                    type="text"
                    label="First Name"
                    {...register("name_first")}
                    errMsg={errors.name_first?.message}
                  />
                  <LabeledInput
                    type="text"
                    label="Last Name"
                    {...register("name_last")}
                    errMsg={errors.name_last?.message}
                  />
                  <LabeledInput
                    type="email"
                    label="Email"
                    {...register("email")}
                    errMsg={errors.email?.message}
                  />
                  <LabeledInput
                    type="password"
                    label="Password"
                    {...register("password")}
                    errMsg={errors.password?.message}
                  />
                  <LabeledInput
                    type="password"
                    label="Confirm Password"
                    {...register("confirmPassword")}
                    errMsg={errors.confirmPassword?.message}
                  />
                  <div className="flex">
                    <div className="m-3 mt-6 flex-1">
                      <Checkbox value="Real Estate Agent" {...register("license")}>
                        Do you have an agent license?
                      </Checkbox>
                      <FormFieldError className="mt-1" field={errors.license} />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="m-3 flex-1">
                      <span className="text-gray-700">Complete registration</span>
                      <Checkbox {...register("acceptTerms")}>
                        I agree to {isPellego ? "Pellego" : "Lotside"}&nbsp;
                        <a
                          href="https://cdn.davinci.pellego.com/static/resources/TermsofService.pdf"
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-700"
                        >
                          terms of services
                        </a>
                      </Checkbox>
                      <FormFieldError className="mt-1" field={errors.acceptTerms} />
                    </div>
                  </div>
                  <SubmitButton
                    buttonClassName="float-right inline-flex items-center my-4 px-8 py-2 font-normal text-md shadow-sm rounded-sm text-white bg-black transition ease-in-out duration-150"
                    isSubmitting={isSubmitting}
                  >
                    Sign Up
                  </SubmitButton>
                </section>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

import { useState } from "react";

export const ImageInPhotoModal = ({photo}: any) => {
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  return (
    <div className="relative w-full justify-center">
      <img
        className="h-auto w-full max-w-full snap-start snap-normal object-cover object-center"
        src={photo.photo_url}
        alt={photo.description || ""}
        onLoad={() => setImageLoading(false)}
      />
      {imageLoading === false && photo.description && (
        <div className="absolute inset-x-0 bottom-0 z-10 mx-auto mb-2 size-fit rounded-full bg-white bg-opacity-60 px-2 py-0.5 text-[12px] text-black">
          {photo.description}
        </div>
      )}
    </div>
  );
};

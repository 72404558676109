import { appConfig } from "config";
import { formatCurrencyK1 } from "helpers";
import L from "leaflet";
import "leaflet.markercluster";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";
import { Position } from "geojson";
import { TypeAheadItemType } from "helpers";
import { isDesktop } from "react-device-detect";
import { SearchResultType, useMapRequirements } from "state/browse";

type AppreciationPeriodType =
  | "none"
  | "pct_growth_three_months"
  | "pct_growth_one_year"
  | "pct_growth_three_years";

const controlsLabel: Record<AppreciationPeriodType, string> = {
  none: "None",
  pct_growth_three_months: "3M",
  pct_growth_one_year: "1Y",
  pct_growth_three_years: "3Y",
};

const AppreciationScaleMuliplier = {
  none: 1.0,
  pct_growth_three_months: 0.5,
  pct_growth_one_year: 1.0,
  pct_growth_three_years: 2.0,
};

const colorScale = [
  ["#ECF4F1", "#FAE7E5"],
  ["#DFECE8", "#F7D7D4"],
  ["#D2E4DF", "#F4C6C3"],
  ["#C5DDD5", "#F1B6B1"],
  ["#B9D5CC", "#EEA6A0"],
  ["#ACCEC3", "#EB968F"],
  ["#9FC6BA", "#E8867D"],
  ["#92BEB1", "#E4766C"],
  ["#85B7A7", "#E1665B"],
  ["#79AF9E", "#DE554A"],
  ["#6CA795", "#DB4538"],
  ["#5FA08C", "#D83527"],
  ["#589380", "#C73124"],
  ["#508675", "#B52D21"],
  ["#487A6A", "#A4281E"],
];

const addDrawingText = "ADD DRAWING";
const addExtraDrawingText = "ADD EXTRA DRAWING";
const removeAllDrawingText = "REMOVE ALL DRAWINGS";

function getHtmlColorFromPct(number: number, appreciation_type: AppreciationPeriodType): string {
  if (appreciation_type === "none") return "#000000";
  const columnIdx = number >= 0 ? 0 : 1;
  const absPct = Math.abs(number);
  const colorIdx = Math.min(
    Math.floor(absPct / AppreciationScaleMuliplier[appreciation_type]),
    colorScale.length - 1,
  );
  return colorScale[colorIdx][columnIdx];
}

const getClusterIcon = (count: number, highlighted: boolean = false): L.DivIcon => {
  let sizeIdx = Math.floor(count / 50);
  if (sizeIdx > 4) sizeIdx = 4;
  const iconSizes = [48, 51, 54, 57, 60];
  return L.divIcon({
    className: `marker-cluster-icon marker-cluster-icon-${sizeIdx + 1}`,
    html: `<img src="https://cdn.davinci.pellego.com/static/images/map/cluster/${
      highlighted ? "c" : "g"
    }${sizeIdx + 1}.png" />${count}`,
    iconSize: [iconSizes[sizeIdx], iconSizes[sizeIdx]],
    iconAnchor: [iconSizes[sizeIdx] / 2, iconSizes[sizeIdx] / 2],
  });
};

function simplifyPolygon(coordinates: any[]) {
  const simplifiedCoordinates = L.LineUtil.simplify(
    coordinates.map((c) => new L.Point(c[0], c[1])),
    0.0001,
  );
  return simplifiedCoordinates.map((c) => [c.x, c.y]);
}

export class LeafletMapService {
  map: L.Map | null = null;

  // Appreciation data
  onlyAppreciation: boolean;
  appreciationType: AppreciationPeriodType = "none";
  locationsAppreciations: Record<string, any> | null | undefined = null;
  appreciationGeoJSONLayer: L.GeoJSON | null = null;
  appreciationLegendLayer: L.Control | null = null;
  appreciationControlLayer: L.Control | null = null;

  // Areas outlines data
  boundariesJSONLayer: L.GeoJSON | null = null;
  areasOutlinesVisible = true;
  areasOutlinesPolygons: number[][] = [];

  // Properties/clusters data
  properties: SearchResultType[] = [];
  markersClusterLayer: L.MarkerClusterGroup | null = null;
  markersClusterLayerVisible = true;

  // Free hand drawing data
  freeHandActive: boolean = false;
  freeHandJSONLayer: L.GeoJSON | null = null;
  freeHandAreas: Record<number, { geoJson: L.GeoJSON; polygon: L.Polygon }> = {};
  freeHandControlLayer: L.Control | null = null;
  freeHandDrawButton: HTMLDivElement | null = null;
  freeHandDelButton: HTMLDivElement | null = null;

  highlightedMarker: L.Marker | null = null;
  mapControlTexts: any = {};
  selectedIdx: number | undefined = undefined; // Marker clicked on the map
  setSelectedProperty: CallableFunction;
  idtoMarkerIdx: Record<number, number> = {};
  removeAreasControlLayer: L.Control | null = null;
  isClient: boolean | undefined;
  locationSearchItems: TypeAheadItemType[] = [];
  mapRequirements: ReturnType<typeof useMapRequirements>;
  setMultiPolygons?: (polygons: L.Polygon[]) => void;
  setMapBoundsParams?: (newVal: Record<string, any>) => void;
  disableMapMoveCallback: boolean = false;
  disableNextFitBounds: boolean = false;
  disableNextZoomUpdate: boolean = false;
  zoomLevel: number;
  toolTipLayer: L.Polygon | null = null;

  constructor(
    setSelectedProperty: CallableFunction,
    onlyAppreciation: boolean,
    isClient: boolean | undefined,
    mapRequirements: ReturnType<typeof useMapRequirements>,
  ) {
    this.setSelectedProperty = setSelectedProperty;
    this.onlyAppreciation = onlyAppreciation;
    this.isClient = isClient;
    this.mapRequirements = mapRequirements;
    this.zoomLevel = onlyAppreciation ? 3 : 11;
    window.Pellego.map = this;
  }

  createMap(domRef: HTMLDivElement) {
    const streetMap = L.maplibreGL({
      style: this.onlyAppreciation
        ? `https://api.maptiler.com/maps/basic-v2-light/style.json?key=${appConfig.mapTilerApiKey}`
        : `https://api.maptiler.com/maps/2eadbf66-831a-4e49-9604-48d6515c3a10/style.json?key=${appConfig.mapTilerApiKey}`,
    });

    const satelliteMap = L.tileLayer("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 18,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });

    this.map = L.map(domRef, {
      zoom: this.zoomLevel,
      minZoom: 7,
      maxZoom: 18,
      attributionControl: false,
      zoomControl: false,
      layers: [streetMap],
    });

    const baseMaps = {
      Street: streetMap,
      Satellite: satelliteMap,
    };

    const overlays = {
      //add any overlays here
    };

    L.control.layers(baseMaps, overlays, { position: "bottomleft" }).addTo(this.map);

    L.control.zoom({ position: "bottomright" }).addTo(this.map);

    this.createAppreciationLayers();

    if (this.onlyAppreciation) {
      this.fetchAppreciationData();
    } else {
      this.createMarkerClusterLayer();

      if (isDesktop) {
        this.createFreeHandControls();
      }
      // this.onClickAppreciationControl("pct_growth_one_year");

      this.map.on("click", () => this.onMapClick());
      this.map.on("moveend", () => this.onMapMoveEnd());
      this.map.on("zoomend", () => this.onZoomEnd());
    }
    this.createAreasOutlines();

    this.setGeoJsonPolygons(this.mapRequirements.geoJsonPolygons);
  }

  onMapClick() {
    if (this.selectedIdx) {
      this.unHighlightItem();
      this.selectedIdx = undefined;
    }
    this.setSelectedProperty(null);
  }

  updateTooltipLayer() {
    if (!this.map) return;

    this.toolTipLayer?.remove();

    if (!this.areasOutlinesVisible || this.freeHandActive) {
      this.toolTipLayer = null;
      return;
    }

    const mapBound = this.map.getBounds();
    const sw = mapBound.getSouthWest();
    const ne = mapBound.getNorthEast();

    const latlngs: any = [
      [ne.lat, ne.lng],
      [ne.lat, sw.lng],
      [sw.lat, sw.lng],
      [sw.lat, ne.lng],
      [ne.lat, ne.lng],
    ];

    this.toolTipLayer = new L.Polygon(latlngs, { opacity: 0, fillOpacity: 0 }).addTo(this.map);
    this.toolTipLayer
      .bindTooltip("Click to see<br />all homes", {
        direction: "right",
        offset: [8, 10],
        className: "mapTooltipOutlines",
        sticky: true,
      })
      .openTooltip();
    this.toolTipLayer.on("click", () => {
      if (this.areasOutlinesVisible) {
        this.onClickRemoveAreasOutlines();
        this.updateTooltipLayer();
      }
    });
    this.toolTipLayer.bringToBack();
  }

  onMapMoveEnd() {
    if (!this.map) return;

    if (this.freeHandActive) return;

    if (this.disableMapMoveCallback) {
      this.disableMapMoveCallback = false;
      return;
    }

    if (this.selectedIdx) {
      this.unHighlightItem();
      this.selectedIdx = undefined;
    }
    this.setSelectedProperty(null);

    if (!this.areasOutlinesVisible) {
      this.updateMapBoundsParam();
      this.disableNextFitBounds = true;
    }

    this.updateTooltipLayer();
  }

  onZoomEnd() {
    if (!this.map) return;
    const newZoonLevel = this.map.getZoom();
    if (!this.disableNextZoomUpdate && newZoonLevel > this.zoomLevel && this.areasOutlinesVisible) {
      this.onClickRemoveAreasOutlines();
    }
    this.disableNextZoomUpdate = false;
    this.zoomLevel = newZoonLevel;
  }

  updateMapBoundsParam() {
    const bounds = this.map?.getBounds();
    if (!bounds || !this.map) return;
    const nw = bounds.getNorthWest();
    const center = bounds.getCenter();
    const lat = center.lat.toFixed(5);
    const lng = center.lng.toFixed(5);
    const width = Math.ceil(this.map.distance(nw, bounds.getNorthEast()));
    const height = Math.ceil(this.map.distance(bounds.getSouthWest(), nw));
    this.setMapBoundsParams?.({ mapBounds: `${lat} ${lng} ${width} ${height}` });
  }

  //
  // Free hand drwaing related methods
  //
  createFreeHandControls() {
    this.createHandDrawControls();
    this.freeHandHandlers();
  }

  createHandDrawControls() {
    const that = this;
    const MyControls = L.Control.extend({
      options: {
        position: "topright",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", "leaflet-control");
        container.setAttribute("id", "browseMapFreeHandControl");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        const divDraw = L.DomUtil.create("div", "mapControl", container);
        that.freeHandDrawButton = L.DomUtil.create("div", "mapControlText", divDraw);
        that.freeHandDrawButton.innerHTML = addDrawingText;

        divDraw.addEventListener("click", that.onClickFreeHandDrawButton.bind(that));

        const divDelete = L.DomUtil.create("div", "mapControl hideRemoveAll", container);
        that.freeHandDelButton = L.DomUtil.create("div", "mapControlText", divDelete);
        that.freeHandDelButton.innerHTML = removeAllDrawingText;

        divDelete.addEventListener("click", that.onClickRemoveAllDrawings.bind(that));

        return container;
      },
    });

    this.freeHandControlLayer = new MyControls();
    this.freeHandControlLayer.addTo(this.map!);
  }

  createRemoveAreasOutlinesControl() {
    const that = this;
    this.hideAreasButton();

    const MyControls = L.Control.extend({
      options: {
        position: "bottomright",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", "leaflet-control");
        container.setAttribute("id", "browseMapRemoveAreasControl");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        const divDel = L.DomUtil.create("div", "mapControl", container);
        divDel.innerHTML = "OUTLINE OFF";

        divDel.addEventListener("click", that.onClickRemoveAreasOutlines.bind(that));

        return container;
      },
    });

    this.removeAreasControlLayer = new MyControls();
    this.removeAreasControlLayer.addTo(this.map!);
  }

  freeHandHandlers() {
    let dragging = false;
    let coordinates: any[];

    if (!this.map) return;

    this.map.on("mousedown", (e) => {
      if (!this.freeHandActive || this.freeHandJSONLayer) return;
      e.target.dragging.disable();
      dragging = true;
      coordinates = [[e.latlng.lng, e.latlng.lat]];
      this.createFreeHandLayer();
      this.freeHandJSONLayer!.addTo(this.map!);
      this.properties = [];
    });

    this.map.on("mousemove", (e) => {
      if (!this.freeHandActive || !dragging) {
        return;
      }
      coordinates.push([e.latlng.lng, e.latlng.lat]);
      const featureCollection: GeoJSON.FeatureCollection<any> = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: coordinates,
              type: "LineString",
            },
          },
        ],
      };
      this.freeHandJSONLayer?.addData(featureCollection);
    });

    this.map.on("mouseup", (e) => {
      if (!this.freeHandActive || !dragging) return;
      this.freeHandActive = false;
      e.target.dragging.enable();
      dragging = false;
      L.DomUtil.removeClass(this.map?.getContainer()!, "leaflet-crosshair");
      this.freeHandDrawButton?.classList.remove("active");

      this.freeHandJSONLayer?.remove();
      this.freeHandJSONLayer = null;

      const simplifiedCoordinates = simplifyPolygon(coordinates);

      if (simplifiedCoordinates.length > 4) {
        this.addFreeHandArea(simplifiedCoordinates);
        this.updateMapDrawnPolygons();
      }

      if (Object.keys(this.freeHandAreas).length > 0) {
        if (this.freeHandDrawButton) this.freeHandDrawButton.innerHTML = addExtraDrawingText;
        this.freeHandDelButton?.parentElement?.classList.remove("hideRemoveAll");
      }
      this.setHandDrawMode(false);
    });
  }

  addFreeHandArea(coordinates: number[][]) {
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [coordinates],
            type: "Polygon",
          },
        },
      ],
    };
    const newArea = L.geoJSON(featureCollection, {
      style: {
        weight: 1,
        color: "#888",
        fillColor: "#888",
        fillOpacity: 0.2,
      },
    });
    const polygon = L.polygon(L.GeoJSON.coordsToLatLngs(coordinates));
    const newAreaId = newArea.getLayerId(newArea);
    newArea.addEventListener("mousedown", () => this.onClickFreeHandAreaRemove(newAreaId));
    this.freeHandAreas[newAreaId] = { geoJson: newArea, polygon };
    newArea.addTo(this.map!);

    newArea.addEventListener("mouseover", () => {
      newArea
        .bindTooltip("Click to remove this area", {
          direction: "right",
          offset: [10, 0],
          className: "mapTooltip",
          sticky: true,
        })
        .openTooltip();
    });
  }

  setGeoJsonPolygons(coordinates: Position[][][] | null | undefined) {
    if (coordinates && coordinates.length > 0) {
      coordinates.forEach((coords) => {
        this.addFreeHandArea(coords[0]);
      });
      this.updateMapDrawnPolygons();
      if (Object.keys(this.freeHandAreas).length > 0) {
        if (this.freeHandDrawButton) this.freeHandDrawButton.innerHTML = addExtraDrawingText;
        this.freeHandDelButton?.parentElement?.classList.remove("hideRemoveAll");
      }
    }
  }

  setHandDrawMode(active: boolean) {
    if (active) {
      this.hideAppreciationsLayers();
      this.hidePropertiesLayers();
      this.hideAreasOutlines();
      this.hideAreasButton();
    } else {
      this.showAppreciationsLayers();
      this.showPropertiesLayers();
      this.showAreasOutlines();
      this.showAreasButton(this.areasOutlinesVisible);
    }
    this.updateTooltipLayer();
  }

  updateMapDrawnPolygons() {
    const polygons: L.Polygon[] = [];
    Object.values(this.freeHandAreas).forEach((area) => {
      polygons.push(area.polygon);
    });
    this.setMultiPolygons?.(polygons);
  }

  onClickFreeHandAreaRemove(areaId: number) {
    this.freeHandAreas[areaId].geoJson.remove();
    delete this.freeHandAreas[areaId];
    if (Object.keys(this.freeHandAreas).length === 0) {
      this.onClickRemoveAllDrawings();
    } else {
      this.updateMapDrawnPolygons();
    }
  }

  createFreeHandLayer() {
    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [],
            type: "LineString",
          },
        },
      ],
    };
    this.freeHandJSONLayer = L.geoJSON(featureCollection, {
      style: {
        weight: 2,
        color: "#888",
      },
    });
  }

  onClickFreeHandDrawButton() {
    let handDrawMode = true;
    this.freeHandActive = !this.freeHandActive;
    this.freeHandDrawButton?.classList.toggle("active", this.freeHandActive);
    if (this.freeHandActive) {
      L.DomUtil.addClass(this.map?.getContainer()!, "leaflet-crosshair");
    } else {
      L.DomUtil.removeClass(this.map?.getContainer()!, "leaflet-crosshair");
      if (Object.keys(this.freeHandAreas).length === 0) {
        handDrawMode = false;
      }
    }
    this.setHandDrawMode(handDrawMode);
  }

  removeAllHandDrawings() {
    Object.values(this.freeHandAreas).forEach((area) => {
      area.geoJson.remove();
    });
    this.freeHandAreas = {};
    this.setHandDrawMode(false);
    this.freeHandDelButton?.parentElement?.classList.add("hideRemoveAll");
    if (this.freeHandDrawButton) this.freeHandDrawButton.innerHTML = addDrawingText;
  }

  onClickRemoveAllDrawings() {
    this.removeAllHandDrawings();
    this.updateMapDrawnPolygons();
  }

  //
  // Marker related methods
  //
  highlightItem(itemIdx: number) {
    if (!this.properties[itemIdx]) return;
    if (this.properties[itemIdx].dont_show_map_link) return;

    // Create new marker
    this.highlightedMarker = new L.Marker(
      [this.properties[itemIdx].latitude, this.properties[itemIdx].longitude],
      {
        icon: this.getMarkerIcon(this.properties[itemIdx], true),
        zIndexOffset: 1000,
      },
    );
    this.highlightedMarker.addEventListener("click", () => this.onClickMarker(itemIdx));
    this.highlightedMarker.addTo(this.map!);
  }

  unHighlightItem() {
    if (!this.highlightedMarker) return;
    this.highlightedMarker.remove();
  }

  highlightHandler(propertyId: number) {
    this.unHighlightItem();

    if (this.selectedIdx) {
      this.selectedIdx = undefined;
    }

    if (!propertyId || !this.markersClusterLayerVisible) return;

    const markerIdx = this.idtoMarkerIdx[propertyId];
    if (markerIdx !== undefined) {
      this.highlightItem(markerIdx);
    }
  }

  onClickMarker(idx: number) {
    const oldSelected = this.selectedIdx;
    this.selectedIdx = oldSelected === idx ? undefined : idx;
    if (oldSelected !== undefined) {
      this.unHighlightItem();
    }
    if (oldSelected !== idx) {
      this.highlightItem(idx);
    }
    if (this.selectedIdx !== undefined) {
      this.setSelectedProperty(this.properties[this.selectedIdx]);
    } else {
      this.setSelectedProperty(null);
    }
  }

  getMarkerIcon(item: SearchResultType, selected: boolean = false): L.DivIcon {
    return L.divIcon({
      className: "marker",
      html: `<span class="marker-text marker-${selected ? "selected" : "neutral"}">
      ${!this.isClient && item.is_wholesale ? "Wholesale" : formatCurrencyK1(item.listingPrice)}</span>`,
      iconSize: [61, 29],
      iconAnchor: [31, 27],
    });
  }

  createMarkers() {
    if (this.markersClusterLayer) {
      const markers: L.Marker[] = [];
      this.idtoMarkerIdx = {};

      this.markersClusterLayer?.clearLayers();

      this.properties.forEach((item, idx) => {
        if (item.dont_show_map_link) return;

        const marker = new L.Marker([item.latitude, item.longitude], { icon: this.getMarkerIcon(item) });
        marker.addEventListener("click", () => this.onClickMarker(idx));
        markers.push(marker);
        this.idtoMarkerIdx[item.parcel_id] = idx;
      });

      this.markersClusterLayer.addLayers(markers);
      this.map?.addLayer(this.markersClusterLayer);
    }
  }

  //
  // Properties/clusters related methods
  //
  createMarkerClusterLayer() {
    this.markersClusterLayer = new L.MarkerClusterGroup({
      disableClusteringAtZoom: 15,
      spiderfyOnMaxZoom: false,
      maxClusterRadius: 45,
      iconCreateFunction: function (cluster) {
        return getClusterIcon(cluster.getChildCount());
      },
    });
  }

  updateResults(
    properties: SearchResultType[],
    mapRequirements: ReturnType<typeof useMapRequirements>,
    mapBounds: string,
  ) {
    this.mapRequirements = mapRequirements;
    this.createAreasOutlines(!!mapBounds);
    this.properties = properties;
    this.createMarkers();
    this.updateAppreciations();
    if (mapBounds && this.markersClusterLayer && !this.disableNextFitBounds && properties.length > 0) {
      this.disableMapMoveCallback = true;
      this.map?.fitBounds(this.markersClusterLayer.getBounds());
    }
    this.disableNextFitBounds = false;
    if (!this.mapRequirements?.geoJsonPolygons || this.mapRequirements?.geoJsonPolygons?.length === 0) {
      this.removeAllHandDrawings();
    }
    this.updateTooltipLayer();
  }

  showPropertiesLayers() {
    if (this.markersClusterLayer && !this.markersClusterLayerVisible) {
      this.markersClusterLayer.addTo(this.map!);
      this.markersClusterLayerVisible = true;
    }
  }

  hidePropertiesLayers() {
    this.markersClusterLayer?.remove();
    this.markersClusterLayerVisible = false;
  }

  //
  // Areas outlines related methods
  //
  createAreasOutlines(initiallyHidden: boolean = false) {
    if (!this.mapRequirements.locationsPolygons) return;

    this.removeAreasOutlines();
    if (initiallyHidden) {
      this.areasOutlinesVisible = false;
      // this.hideAreasButton();
      this.showAreasButton(this.areasOutlinesVisible);
      return;
    }
    this.areasOutlinesVisible = true;

    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: this.mapRequirements.locationsPolygons.map((polygon: any) => {
        return {
          type: "Feature",
          properties: {},
          geometry: polygon,
        };
      }),
    };

    this.boundariesJSONLayer = L.geoJSON(featureCollection, {
      style: {
        weight: 2,
        color: "#888",
        fillOpacity: 0.1,
      },
    });
    this.boundariesJSONLayer.addTo(this.map!);
    this.boundariesJSONLayer.bringToBack();

    this.map?.fitBounds(this.boundariesJSONLayer.getBounds());
    this.createRemoveAreasOutlinesControl();
  }

  onClickRemoveAreasOutlines() {
    this.areasOutlinesVisible = !this.areasOutlinesVisible;
    if (this.areasOutlinesVisible) {
      this.showAreasOutlines();
      this.showAreasButton(this.areasOutlinesVisible);
      this.setMapBoundsParams?.({ mapBounds: "" });
      this.disableNextZoomUpdate = true;
    } else {
      this.removeAreasOutlines();
      this.showAreasButton(this.areasOutlinesVisible);
      this.updateMapBoundsParam();
    }
  }

  showAreasOutlines() {
    if (this.areasOutlinesVisible) {
      this.boundariesJSONLayer?.addTo(this.map!);
      this.boundariesJSONLayer?.bringToBack();
      this.updateTooltipLayer();
    }
  }

  removeAreasOutlines() {
    this.boundariesJSONLayer?.remove();
  }

  hideAreasOutlines() {
    this.boundariesJSONLayer?.remove();
  }

  hideAreasButton() {
    this.removeAreasControlLayer?.remove();
  }

  showAreasButton(outlinesVisibles: boolean) {
    this.removeAreasControlLayer?.addTo(this.map!);
    const buttonDiv = this.removeAreasControlLayer?.getContainer()?.children[0];
    if (buttonDiv) {
      buttonDiv.innerHTML = outlinesVisibles ? "OUTLINE OFF" : "OUTLINE ON";
    }
  }

  //
  // Appreciation related methods
  //
  createAppreciationLayers() {
    this.appreciationType = this.onlyAppreciation ? "pct_growth_one_year" : "none";
    this.appreciationControlLayer = this.createAppreciationControls();
    this.appreciationControlLayer.addTo(this.map!);
    this.appreciationLegendLayer = this.createAppreciationLegends();
    if (this.onlyAppreciation) {
      this.appreciationLegendLayer.addTo(this.map!);
    }
  }

  createAppreciationButton(container: HTMLDivElement, controlType: AppreciationPeriodType) {
    const selected = this.appreciationType === controlType;
    const div = L.DomUtil.create("div", "mapControl", container);
    const textDiv = L.DomUtil.create("div", "mapControlText" + (selected ? " active" : ""), div);
    this.mapControlTexts[controlType] = textDiv;
    textDiv.innerHTML = controlsLabel[controlType as keyof typeof controlsLabel];
    div.addEventListener("click", () => {
      this.onClickAppreciationControl(controlType);
    });
  }

  createAppreciationControls() {
    const that = this;
    const MyControls = L.Control.extend({
      options: {
        position: "topleft",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", "pellego-leaflet-control");
        container.setAttribute("id", "browseMapControls");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        const div = L.DomUtil.create("div", "mapControl", container);
        const textDiv = L.DomUtil.create("div", "mapControlLabel", div);
        textDiv.innerHTML = "Appreciation:";

        if (!that.onlyAppreciation) {
          that.createAppreciationButton(container, "none");
        }
        that.createAppreciationButton(container, "pct_growth_three_months");
        that.createAppreciationButton(container, "pct_growth_one_year");
        that.createAppreciationButton(container, "pct_growth_three_years");

        return container;
      },
    });

    return new MyControls();
  }

  createAppreciationLegends() {
    const that = this;
    const LegendControl = L.Control.extend({
      options: {
        position: "topleft",
      },

      onAdd: function (map: L.Map) {
        const container = L.DomUtil.create("div", "pellego-appreciation-legend");
        container.setAttribute("id", "browseMapLegend");
        L.DomEvent.disableClickPropagation(container);
        L.DomEvent.disableScrollPropagation(container);

        that.createLegendTable(container);
        return container;
      },
    });

    return new LegendControl();
  }

  createLegendTable(container: HTMLDivElement) {
    const table = L.DomUtil.create("table", "legendTable", container);
    const pctPctMultiplier = AppreciationScaleMuliplier[this.appreciationType];

    colorScale
      .slice()
      .reverse()
      .forEach((color, idx) => {
        if (idx % 3 === 0) {
          const tr = L.DomUtil.create("tr", undefined, table);
          const tdColor = L.DomUtil.create("td", "lgColor", tr);
          const tdText = L.DomUtil.create("td", "lgText", tr);
          tdColor.style.backgroundColor = color[0];
          tdText.innerHTML = `+${((15 - idx) * pctPctMultiplier).toFixed(pctPctMultiplier < 1 ? 1 : 0)}%`;
        }
      });
    const tr = L.DomUtil.create("tr", undefined, table);
    const tdColor = L.DomUtil.create("td", "lgColor", tr);
    const tdText = L.DomUtil.create("td", "lgText", tr);
    tdColor.style.backgroundColor = "#fff";
    tdText.innerHTML = `0%`;
    colorScale.forEach((color, idx) => {
      if (idx % 3 === 2) {
        const tr = L.DomUtil.create("tr", undefined, table);
        const tdColor = L.DomUtil.create("td", "lgColor", tr);
        const tdText = L.DomUtil.create("td", "lgText", tr);
        tdColor.style.backgroundColor = color[1];
        tdText.innerHTML = `-${((idx + 1) * pctPctMultiplier).toFixed(pctPctMultiplier < 1 ? 1 : 0)}%`;
      }
    });
  }

  onClickAppreciationControl(controlType: AppreciationPeriodType) {
    if (this.appreciationType === controlType) return;

    this.mapControlTexts[this.appreciationType].classList.remove("active");
    this.mapControlTexts[controlType].classList.add("active");
    this.appreciationType = controlType;

    if (this.appreciationGeoJSONLayer) {
      this.map?.removeLayer(this.appreciationGeoJSONLayer);
      this.appreciationGeoJSONLayer = null;
    }

    if (this.appreciationType === "none") {
      this.appreciationLegendLayer?.remove();
    } else {
      this.appreciationLegendLayer?.addTo(this.map!);
      if (!this.locationsAppreciations) {
        this.fetchAppreciationData();
      } else {
        this.addAppreciationPolygons();
      }
    }
  }

  fetchAppreciationData() {
    if (!this.map) return;

    this.map.getContainer()?.style.setProperty("cursor", "wait");
    let fetcher = this.mapRequirements.fetchLocationAppreciations();

    if (!this.areasOutlinesVisible) {
      const mapBounds = this.map.getBounds();
      const se = mapBounds.getSouthEast();
      const nw = mapBounds.getNorthWest();
      fetcher = this.mapRequirements.fetchBoxAppreciations(se.lat, se.lng, nw.lat, nw.lng);
    }

    fetcher.then((locAppr) => {
      this.locationsAppreciations = locAppr;
      this.addAppreciationPolygons();
      this.map?.getContainer()?.style.setProperty("cursor", null);
    });
  }

  colorlayer = (feature: any, layer: any) => {
    const color = getHtmlColorFromPct(feature.properties[this.appreciationType], this.appreciationType);
    layer.setStyle({
      fillColor: color,
    });
    layer
      .bindTooltip(
        `Zip code: <b>${feature.properties.zipCode}</b><br/>
          Appreciation:<br/>
          &nbsp;3M: <b>${feature.properties.pct_growth_three_months.toFixed(2)}%</b><br/>
          &nbsp;1Y: <b>${feature.properties.pct_growth_one_year.toFixed(2)}%</b><br/>
          &nbsp;3Y: <b>${feature.properties.pct_growth_three_years.toFixed(2)}%</b>`,
        {
          direction: "right",
          offset: [10, 0],
          className: "mapTooltip",
          sticky: true,
        },
      )
      .openTooltip();
  };

  addAppreciationPolygons() {
    if (!this.locationsAppreciations || this.appreciationType === "none") return;
    const zipCodes = Object.keys(this.locationsAppreciations);
    if (zipCodes.length === 0) return;

    const featureCollection: GeoJSON.FeatureCollection<any> = {
      type: "FeatureCollection",
      features: zipCodes.map((zipCode) => {
        return {
          type: "Feature",
          properties: {
            zipCode,
            pct_growth_three_months: this.locationsAppreciations![zipCode].pct_growth_three_months,
            pct_growth_one_year: this.locationsAppreciations![zipCode].pct_growth_one_year,
            pct_growth_three_years: this.locationsAppreciations![zipCode].pct_growth_three_years,
          },
          geometry: this.locationsAppreciations![zipCode].geom,
        };
      }),
    };

    this.appreciationGeoJSONLayer = L.geoJSON(featureCollection, {
      onEachFeature: this.colorlayer,
      style: {
        weight: 1,
        fillOpacity: 0.55,
        color: "#CCC",
      },
    });
    this.map?.addLayer(this.appreciationGeoJSONLayer);
    this.appreciationGeoJSONLayer.bringToBack();
    this.boundariesJSONLayer?.bringToBack();
    this.toolTipLayer?.bringToBack();

    if (this.onlyAppreciation) {
      this.map?.fitBounds(this.appreciationGeoJSONLayer.getBounds());
      this.appreciationLegendLayer?.addTo(this.map!);
    }
  }

  hideAppreciationsLayers() {
    this.appreciationGeoJSONLayer?.remove();
    this.appreciationControlLayer?.remove();
    this.appreciationLegendLayer?.remove();
  }

  showAppreciationsLayers() {
    this.appreciationGeoJSONLayer?.addTo(this.map!);
    this.appreciationGeoJSONLayer?.bringToBack();
    this.appreciationControlLayer?.addTo(this.map!);
    if (this.appreciationType !== "none") {
      this.appreciationLegendLayer?.addTo(this.map!);
    }
  }

  updateAppreciations() {
    if (this.appreciationGeoJSONLayer) {
      this.map?.removeLayer(this.appreciationGeoJSONLayer);
      this.appreciationGeoJSONLayer = null;
    }
    if (this.locationsAppreciations) {
      this.fetchAppreciationData();
    }
  }

  clearMap() {
    this.map?.off();
    this.map?.remove();
  }
}

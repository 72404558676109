const DEFAULT_API_BASE_URL = `//newton.${window.location.hostname.includes("lotside.com") ? "lotside.com/api/v1" : "pellego.com/api/v1"}`;
const API_BASE_URL = process.env.REACT_APP_PELLEGO_API_URL || DEFAULT_API_BASE_URL;
export const isPellego = window.location.hostname.includes("pellego.com");
export const isStage = window.location.hostname.includes("stage.");

export const appConfig = {
  appName: "Pellego",
  appVersion: "2.0.0",
  apiBaseUrl: API_BASE_URL,
  googleMapsKey: "AIzaSyDVR0SPsXCcqDKiCNQXbJBDx1o-gby6CVw",
  mapTilerApiKey: "CBdbtWVFoqHihnSePAHl",
  reCaptchaPublicKey: "6LdEh-YpAAAAAIgSR04aGz4Ftko_5LMLLhrGjAWk",

  // PROFORMA ENDPOINTS
  apiEndpoint: {
    // PROFORMA ENDPOINTS
    proforma: API_BASE_URL + "/proformas/:proforma_id",
    proformas: API_BASE_URL + "/proformas/",
    proformaAll: API_BASE_URL + "/proformas/all",
    proformaCopy: API_BASE_URL + "/proformas/copy/:proforma_id",
    proformaSearch: API_BASE_URL + "/proformas/search",
    proformaAddressBasic: API_BASE_URL + "/proformas/basic/:full_address",
    proformaBrowseLocation: API_BASE_URL + "/proformas/browse_location",
    proformaBrowseCoordinates: API_BASE_URL + "/proformas/browse_coordinates",
    proformaAddress: API_BASE_URL + "/proformas/address/:full_address",
    proformaListingNumber: API_BASE_URL + "/proformas/listing_number/:listing_number",
    proformaCancelledExpiredSearch: API_BASE_URL + "/proformas/cancelled_expired/:state_id",
    proformaTypeahead: API_BASE_URL + "/proformas/typeahead",
    proformaViewing: API_BASE_URL + "/proformas/viewing",

    // PARCEL ENDPOINTS
    customStructure: API_BASE_URL + "/parcels/:parcel_id/structure",
    moreComparables: API_BASE_URL + "/parcels/:parcel_id/more_comparables",
    moreRentalComparables: API_BASE_URL + "/parcels/:parcel_id/more_rental_comparables",
    parcelHistory: API_BASE_URL + "/parcels/:parcel_id/history",
    parcelRental: API_BASE_URL + "/parcels/:parcel_id/rental_info",
    parcelLoans: API_BASE_URL + "/parcels/:parcel_id/loans",
    parcelPhotos: API_BASE_URL + "/parcels/:parcel_id/photos",
    parcelMarketData: API_BASE_URL + "/parcels/:parcel_id/market_data",
    parcelAgent: API_BASE_URL + "/parcels/:parcel_id/agent",
    parcelUpdateDate: API_BASE_URL + "/parcels/:state_id/update_date",

    // MARKET DATA ENDPOINTS
    marketDataAvailability: API_BASE_URL + "/market_data/availability/:region",
    marketDataTopPerformers: API_BASE_URL + "/market_data/top_performers/:region",
    marketDataBottomPerformers: API_BASE_URL + "/market_data/bottom_performers/:region",
    marketDataSearchPerformers: API_BASE_URL + "/market_data/performers_by_market",
    marketData: API_BASE_URL + "/market_data/:loc_type/:loc_id/:structure_type",

    // LOCATION PRICE DATA ENDPOINTS
    locationPriceData: API_BASE_URL + "/locations/price_over_time/:loc_type/:loc_id",

    // USER ENDPOINT
    user: API_BASE_URL + "/users/",
    userAgent: API_BASE_URL + "/users/agent?agent_id=:agent_id",
    userSearchFilters: API_BASE_URL + "/users/:user_id/search-filters",
    userFromToken: API_BASE_URL + "/users/:token",

    // Client Signup Endpoint
    clientSignup: API_BASE_URL + "/client_signup/",

    // AGENT ENDPOINT
    agent: API_BASE_URL + "/agent/:agent_slug",
    agentClients: API_BASE_URL + "/agent/clients",
    reviewStatus: API_BASE_URL + "/proformas/pursue/:deal_id",

    // EMAIL ENDPOINTS
    applicant: API_BASE_URL + "/careers/",
    proformaShare: API_BASE_URL + "/email/share",
    proformaEmail: API_BASE_URL + "/email/proforma",
    submitFeedback: API_BASE_URL + "/email/feedback",
    askAgent: API_BASE_URL + "/email/ask-agent",
    wholesaleContact: API_BASE_URL + "/email/wholesale-contact",
    restrictedInfoContact: API_BASE_URL + "/email/restricted-contact",

    // EXPORTER ENDPOINTS
    exporterSheet: API_BASE_URL + "/exporter?deal_id=:deal_id&format=:format",

    exporterDeals: API_BASE_URL + "/exporter/cancelledexpired?state_id=:state_id",

    // LOCATION:
    locations: API_BASE_URL + "/locations",
    locationPolygon: API_BASE_URL + "/locations/polygon/:type/:id",
    locationAppreciation: API_BASE_URL + "/locations/appreciation/:type/:id",
    boxAppreciation: API_BASE_URL + "/locations/appreciation/box/:slat_elng_nlat_wlng",
    locationGeoJson: API_BASE_URL + "/locations/geojson",

    // AUTH ENDPOINTS
    uploadAvatar: API_BASE_URL + "/auth/upload-avatar",
    changePassword: API_BASE_URL + "/auth/change-password",
    forgotPassword: API_BASE_URL + "/auth/forgot-password",
    login: API_BASE_URL + "/auth/login",
    logout: API_BASE_URL + "/auth/logout",
    register: API_BASE_URL + "/auth/register",
    resetPassword: API_BASE_URL + "/auth/reset-password/",
    session: API_BASE_URL + "/auth/session", // '/json/session.json',
    requestEmailConfirmation: API_BASE_URL + "/auth/reconfirm/:email",
    confirmEmail: API_BASE_URL + "/auth/confirm/:token",

    // REVIEWER ENDPOINTS
    rentalAppreciationGraph: API_BASE_URL + "/parcels/:parcel_id/rental_appreciation",
    flipAppreciationGraph: API_BASE_URL + "/parcels/:parcel_id/flip_appreciation/:arv",
    compAppreciationGraph: API_BASE_URL + "/parcels/:parcel_id/comp_appreciation",
    rentalCompAppreciationGraph: API_BASE_URL + "/parcels/:parcel_id/rental_comp_appreciation",

    // SITE CONFIG ENDPOINTS
    siteConfig: API_BASE_URL + "/config",
  },
  browse: {
    defaultPageSize: 12,
    showAd: false,
    showAdIdx: 6,
    statesWithAds: [53, 12, 6],
    browseApiPageOffset: 12 * 40,
  },

  stopWords: [
    "i",
    "me",
    "my",
    "myself",
    "we",
    "our",
    "ours",
    "ourselves",
    "you",
    "your",
    "yours",
    "yourself",
    "yourselves",
    "he",
    "him",
    "his",
    "himself",
    "she",
    "her",
    "hers",
    "herself",
    "it",
    "its",
    "itself",
    "they",
    "them",
    "their",
    "theirs",
    "themselves",
    "what",
    "which",
    "who",
    "whom",
    "this",
    "that",
    "these",
    "those",
    "am",
    "is",
    "are",
    "was",
    "were",
    "be",
    "been",
    "being",
    "have",
    "has",
    "had",
    "having",
    "do",
    "does",
    "did",
    "doing",
    "a",
    "an",
    "the",
    "and",
    "but",
    "if",
    "or",
    "because",
    "as",
    "until",
    "while",
    "of",
    "at",
    "by",
    "for",
    "with",
    "about",
    "against",
    "between",
    "into",
    "through",
    "during",
    "before",
    "after",
    "above",
    "below",
    "to",
    "from",
    "up",
    "down",
    "in",
    "out",
    "on",
    "off",
    "over",
    "under",
    "again",
    "further",
    "then",
    "once",
    "here",
    "there",
    "when",
    "where",
    "why",
    "how",
    "all",
    "any",
    "both",
    "each",
    "few",
    "more",
    "most",
    "other",
    "some",
    "such",
    "no",
    "nor",
    "not",
    "only",
    "own",
    "same",
    "so",
    "than",
    "too",
    "very",
    "s",
    "t",
    "can",
    "will",
    "just",
    "don",
    "should",
    "now",
  ],
};

export const CALIFORNIA_STATE_ID = 6;
export const FLORIDA_STATE_ID = 12;
export const WASHINGTON_STATE_ID = 53;
export const ILLINOIS_STATE_ID = 17;
export const TEXAS_STATE_ID = 48;
export const COLORADO_STATE_ID = 8;
export const GEORGIA_STATE_ID = 13;
export const ARIZONA_STATE_ID = 4;
export const OREGON_STATE_ID = 41;

export const stateNameToStateId = {
  alabama: 1,
  alaska: 2,
  arizona: 4,
  arkansas: 5,
  california: 6,
  colorado: 8,
  connecticut: 9,
  delaware: 10,
  "district of columbia": 11,
  florida: 12,
  georgia: 13,
  hawaii: 15,
  idaho: 16,
  illinois: 17,
  indiana: 18,
  iowa: 19,
  kansas: 20,
  kentucky: 21,
  louisiana: 22,
  maine: 23,
  maryland: 24,
  massachusetts: 25,
  michigan: 26,
  minnesota: 27,
  mississippi: 28,
  missouri: 29,
  montana: 30,
  nebraska: 31,
  nevada: 32,
  "new hampshire": 33,
  "new jersey": 34,
  "new mexico": 35,
  "new york": 36,
  "north carolina": 37,
  "north dakota": 38,
  ohio: 39,
  oklahoma: 40,
  oregon: 41,
  pennsylvania: 42,
  "rhode island": 44,
  "south carolina": 45,
  "south dakota": 46,
  tennessee: 47,
  texas: 48,
  utah: 49,
  vermont: 50,
  virginia: 51,
  washington: 53,
  "west virginia": 54,
  wisconsin: 55,
  wyoming: 56,
};

export const stateAbbreviationToStateId = {
  AL: 1,
  AK: 2,
  AZ: 4,
  AR: 5,
  CA: 6,
  CO: 8,
  CT: 9,
  DE: 10,
  DC: 11,
  FL: 12,
  GA: 13,
  HI: 15,
  ID: 16,
  IL: 17,
  IN: 18,
  IA: 19,
  KS: 20,
  KY: 21,
  LA: 22,
  ME: 23,
  MD: 24,
  MA: 25,
  MI: 26,
  MN: 27,
  MS: 28,
  MO: 29,
  MT: 30,
  NE: 31,
  NV: 32,
  NH: 33,
  NJ: 34,
  NM: 35,
  NY: 36,
  NC: 37,
  ND: 38,
  OH: 39,
  OK: 40,
  OR: 41,
  PA: 42,
  RI: 44,
  SC: 45,
  SD: 46,
  TN: 47,
  TX: 48,
  UT: 49,
  VT: 50,
  VA: 51,
  WA: 53,
  WV: 54,
  WI: 55,
  WY: 56,
};

export const stateIdToStateData = {
  1: { name: "Alabama", abbr: "Ala.", code: "AL" },
  2: { name: "Alaska", abbr: "", code: "AK" },
  4: { name: "Arizona", abbr: "Ariz.", code: "AZ" },
  5: { name: "Arkansas", abbr: "Ark.", code: "AR" },
  6: { name: "California", abbr: "Calif.", code: "CA" },
  8: { name: "Colorado", abbr: "Colo.", code: "CO" },
  9: { name: "Connecticut", abbr: "Conn.", code: "CT" },
  10: { name: "Delaware", abbr: "Del.", code: "DE" },
  11: { name: "District of Columbia", abbr: "D.C.", code: "DC" },
  12: { name: "Florida", abbr: "Fla.", code: "FL" },
  13: { name: "Georgia", abbr: "Ga.", code: "GA" },
  15: { name: "Hawaii", abbr: "", code: "HI" },
  16: { name: "Idaho", abbr: "", code: "ID" },
  17: { name: "Illinois", abbr: "Ill.", code: "IL" },
  18: { name: "Indiana", abbr: "Ind.", code: "IN" },
  19: { name: "Iowa", abbr: "", code: "IA" },
  20: { name: "Kansas", abbr: "Kans.", code: "KS" },
  21: { name: "Kentucky", abbr: "Ky.", code: "KY" },
  22: { name: "Louisiana", abbr: "", code: "LA" },
  23: { name: "Maine", abbr: "Me.", code: "ME" },
  24: { name: "Maryland", abbr: "Md.", code: "MD" },
  25: { name: "Massachusetts", abbr: "Mass.", code: "MA" },
  26: { name: "Michigan", abbr: "Mich.", code: "MI" },
  27: { name: "Minnesota", abbr: "Minn.", code: "MN" },
  28: { name: "Mississippi", abbr: "Miss.", code: "MS" },
  29: { name: "Missouri", abbr: "Mo.", code: "MO" },
  30: { name: "Montana", abbr: "Mont.", code: "MT" },
  31: { name: "Nebraska", abbr: "Nebr.", code: "NE" },
  32: { name: "Nevada", abbr: "Nev.", code: "NV" },
  33: { name: "New Hampshire", abbr: "N.H.", code: "NH" },
  34: { name: "New Jersey", abbr: "N.J.", code: "NJ" },
  35: { name: "New Mexico", abbr: "N.Mex.", code: "NM" },
  36: { name: "New York", abbr: "N.Y.", code: "NY" },
  37: { name: "North Carolina", abbr: "N.C.", code: "NC" },
  38: { name: "North Dakota", abbr: "", code: "ND" },
  39: { name: "Ohio", abbr: "", code: "OH" },
  40: { name: "Oklahoma", abbr: "Okla.", code: "OK" },
  41: { name: "Oregon", abbr: "Ore.", code: "OR" },
  42: { name: "Pennsylvania", abbr: "Penn.", code: "PA" },
  44: { name: "Rhode Island", abbr: "R.I.", code: "RI" },
  45: { name: "South Carolina", abbr: "S.C.", code: "SC" },
  46: { name: "South Dakota", abbr: "", code: "SD" },
  47: { name: "Tennessee", abbr: "Tenn.", code: "TN" },
  48: { name: "Texas", abbr: "Tex.", code: "TX" },
  49: { name: "Utah", abbr: "", code: "UT" },
  50: { name: "Vermont", abbr: "Vt.", code: "VT" },
  51: { name: "Virginia", abbr: "Va.", code: "VA" },
  53: { name: "Washington", abbr: "Wash.", code: "WA" },
  54: { name: "West Virginia", abbr: "W.Va.", code: "WV" },
  55: { name: "Wisconsin", abbr: "Wis.", code: "WI" },
  56: { name: "Wyoming", abbr: "Wyo.", code: "WY" },
};

export const stateIdToGeoCenter = {
  [CALIFORNIA_STATE_ID]: {
    lat: 36.778259,
    lng: -119.417931,
  },
  [FLORIDA_STATE_ID]: {
    lat: 27.9881,
    lng: -82.5,
  },
  [WASHINGTON_STATE_ID]: {
    lat: 47.411618,
    lng: -120.592819,
  },
};

export const listingsData = [
  { name: "Arizona Regional Multiple Listing Service, Inc", abbr: "ARMLS", logoURL: null, logoHeight: 4 },
  { name: "Austin Board of Realtors", abbr: "ABOR", logoURL: null, logoHeight: 4 },
  { name: "Bright MLS", abbr: "BRIGHT MLS", logoURL: null, logoHeight: 4 },
  { name: "Bright", abbr: "BRIGHT_MRIS", logoURL: null, logoHeight: 4 },
  { name: "CAAR", abbr: "CAAR", logoURL: null, logoHeight: 4 },
  { name: "California Regional MLS", abbr: "CRMLS", logoURL: null, logoHeight: 4 },
  { name: "CANOPY MLS", abbr: "CANOPYMLS", logoURL: null, logoHeight: 4 },
  { name: "Canopy Realtor Association", abbr: "Canopy", logoURL: null, logoHeight: 4 },
  { name: "CAR", abbr: "CAR", logoURL: null, logoHeight: 4 },
  { name: "Carolina Smokies Association of Realtors", abbr: "CSAOR", logoURL: null, logoHeight: 4 },
  { name: "Catawba Valley Association of Realtors", abbr: "CVAR", logoURL: null, logoHeight: 4 },
  { name: "CCAR", abbr: "CCAR", logoURL: null, logoHeight: 4 },
  { name: "Central Carolina Association of Realtors", abbr: "CCAR", logoURL: null, logoHeight: 4 },
  { name: "Central Viginia Regional MLS", abbr: "CVR MLS", logoURL: null, logoHeight: 4 },
  { name: "Charlottesville", abbr: "CAAR", logoURL: null, logoHeight: 4 },
  { name: "Chicago Association of Realtors", abbr: "MRED", logoURL: null, logoHeight: 4 },
  { name: "Contra Costa Association of Realtors", abbr: "CCAOR", logoURL: null, logoHeight: 4 },
  { name: "East Tennessee Realtors MLS", abbr: "ETNR", logoURL: null, logoHeight: 4 },
  { name: "East Tennessee Realtors", abbr: "ETNR", logoURL: null, logoHeight: 4 },
  { name: "First Multiple Listing Service", abbr: "FMLS", logoURL: null, logoHeight: 4 },
  { name: "Gaston Association of Realtors", abbr: "GAR", logoURL: null, logoHeight: 4 },
  { name: "Georgia MLS", abbr: "GAMLS", logoURL: null, logoHeight: 4 },
  { name: "GHAR", abbr: "GHAR", logoURL: null, logoHeight: 4 },
  { name: "Greater Alabama MLS", abbr: "GABMLS", logoURL: null, logoHeight: 4 },
  { name: "Greater Las Vegas Association of Realtors", abbr: "GLVAR", logoURL: null, logoHeight: 4 },
  { name: "Heartland MLS", abbr: "HMLS", logoURL: null, logoHeight: 4 },
  { name: "Hendersonville Board of Realtors", abbr: "HBOR", logoURL: null, logoHeight: 4 },
  { name: "JSMLS", abbr: "JSMLS", logoURL: null, logoHeight: 4 },
  { name: "Knoxville Area Association of Realtors", abbr: "KAARMLS", logoURL: null, logoHeight: 4 },
  { name: "Land of The Sky Association of Realtors", abbr: "LOTSAR", logoURL: null, logoHeight: 4 },
  { name: "Las Vegas Realtors", abbr: "LVR", logoURL: null, logoHeight: 4 },
  { name: "LCAOR", abbr: "LCAOR", logoURL: null, logoHeight: 4 },
  { name: "Maimi Association of Realtors", abbr: "MARMLS", logoURL: null, logoHeight: 4 },
  { name: "MARIS MLS", abbr: "MARIS", logoURL: null, logoHeight: 4 },
  { name: "Michigan Regional Information Center", abbr: "MichRIC", logoURL: null, logoHeight: 4 },
  { name: "MLS Listings", abbr: "MLSListings", logoURL: null, logoHeight: 4 },
  { name: "MLS Now", abbr: "MLSNOW", logoURL: null, logoHeight: 4 },
  { name: "MLS PIN", abbr: "MLSPIN", logoURL: null, logoHeight: 4 },
  { name: "MLSListings Inc.", abbr: "MLSL", logoURL: null, logoHeight: 4 },
  { name: "MLSListings, Inc.MLSL", abbr: "MLSL", logoURL: null, logoHeight: 4 },
  { name: "MRIS", abbr: "MRIS", logoURL: null, logoHeight: 4 },
  { name: "Northern Great Lakes Realtors", abbr: "NGLR", logoURL: null, logoHeight: 4 },
  { name: "NTREIS", abbr: "NTREIS", logoURL: null, logoHeight: 4 },
  { name: "NWMLS", abbr: "NWMLS", logoURL: "https://cdn.davinci.pellego.com/static/images/listinglogos/nwmls.png", logoHeight: 4 },
  { name: "Pensacola Association of Realtors", abbr: "PAR MLS", logoURL: null, logoHeight: 4 },
  { name: "Piedmont Regional Association of Realtors", abbr: "PRAR", logoURL: null, logoHeight: 4 },
  { name: "Prime MLS", abbr: "PRIMEMLS", logoURL: null, logoHeight: 4 },
  { name: "RAYAC", abbr: "RAYAC", logoURL: null, logoHeight: 4 },
  { name: "RE Colorado", abbr: "RE Colorado", logoURL: null, logoHeight: 4 },
  { name: "RealComp", abbr: "RealComp", logoURL: null, logoHeight: 4 },
  { name: "RealSource", abbr: "RealSource", logoURL: null, logoHeight: 4 },
  { name: "RMLS", abbr: "RMLS", logoURL: null, logoHeight: 4 },
  { name: "SABOR", abbr: "SABOR", logoURL: "https://cdn.davinci.pellego.com/static/images/listinglogos/sabor.png", logoHeight: 5 },
  { name: "San Diego MLS", abbr: "SDMLS", logoURL: null, logoHeight: 4 },
  { name: "San Fransisco Association of Realtors", abbr: "SFAOR", logoURL: null, logoHeight: 4 },
  { name: "SCAOR", abbr: "SCAOR", logoURL: null, logoHeight: 4 },
  { name: "South Central Wisconsin MLS", abbr: "SCWMLS", logoURL: null, logoHeight: 4 },
  { name: "Southern Missouri Regional MLS", abbr: "SOMO", logoURL: null, logoHeight: 4 },
  { name: "Stellar MLS", abbr: "Stellar", logoURL: null, logoHeight: 4 },
  { name: "SWMLS", abbr: "SWMLS", logoURL: null, logoHeight: 4 },
  { name: "Trend MLS", abbr: "TREND", logoURL: null, logoHeight: 4 },
  { name: "Willamette Valley MLS", abbr: "WVMLS", logoURL: null, logoHeight: 4 },
];

export const listingSourceLongToShort: Record<string, string> = {
  "Arizona Regional Multiple Listing Service, Inc": "ARMLS",
  "Austin Board of Realtors": "ABOR",
  "Bright MLS": "BRIGHT MLS",
  "BRIGHT_MRIS": "Bright",
  "CAAR": "CAAR",
  "California Regional MLS": "CRMLS",
  "CANOPY MLS": "CANOPYMLS",
  "Canopy Realtor Association": "Canopy",
  "CAR": "CAR",
  "Carolina Smokies Association of Realtors": "CSAOR",
  "Catawba Valley Association of Realtors": "CVAR",
  "CCAR": "CCAR",
  "Central Carolina Association of Realtors": "CCAR",
  "Central Viginia Regional MLS": "CVR MLS",
  "Charlottesville": "CAAR",
  "Chicago Association of Realtors": "MRED",
  "Contra Costa Association of Realtors": "CCAOR",
  "East Tennessee Realtors MLS": "ETNR",
  "East Tennessee Realtors": "ETNR",
  "First Multiple Listing Service": "FMLS",
  "Gaston Association of Realtors": "GAR",
  "Georgia MLS": "GAMLS",
  "GHAR": "GHAR",
  "Greater Alabama MLS": "GABMLS",
  "Greater Las Vegas Association of Realtors": "GLVAR",
  "Heartland MLS": "HMLS",
  "Hendersonville Board of Realtors": "HBOR",
  "JSMLS": "JSMLS",
  "Knoxville Area Association of Realtors": "KAARMLS",
  "Land of The Sky Association of Realtors": "LOTSAR",
  "Las Vegas Realtors": "LVR",
  "LCAOR": "LCAOR",
  "Maimi Association of Realtors": "MARMLS",
  "MARIS MLS": "MARIS",
  "Michigan Regional Information Center": "MichRIC",
  "MLS Listings": "MLSListings",
  "MLS Now": "MLSNOW",
  "MLS PIN": "MLSPIN",
  "MLSListings Inc.": "MLSL",
  "MLSListings, Inc.MLSL": "MLSL",
  "MRIS": "MRIS",
  "Northern Great Lakes Realtors": "NGLR",
  "NWMLS": "NWMLS",
  "Pensacola Association of Realtors": "PAR MLS",
  "Piedmont Regional Association of Realtors": "PRAR",
  "Prime MLS": "PRIMEMLS",
  "RAYAC": "RAYAC",
  "RE Colorado": "RE Colorado",
  "RealComp": "RealComp",
  "RealSource": "RealSource",
  "RMLS": "RMLS",
  "SABOR": "SABOR",
  "San Diego MLS": "SDMLS",
  "San Fransisco Association of Realtors": "SFAOR",
  "SCAOR": "SCAOR",
  "South Central Wisconsin MLS": "SCWMLS",
  "Southern Missouri Regional MLS": "SOMO",
  "Stellar MLS": "Stellar",
  "SWMLS": "SWMLS",
  "Trend MLS": "TREND",
  "Willamette Valley MLS": "WVMLS",
};

export const loanTypesToNormalized: Record<string, string> = {
  "Stand Alone First": "Conventional",
  "Stand Alone Second": "Second",
  "ARM (Adjustable Rate Mortgage as of August, 2009)": "ARM",
  "Amount keyed is an Aggregate amount": "Consolidated",
  "USDA": "USDA",
  "Closed-end Mortgage": "Conventional",
  "Assumption": "Assumption",
  "Building or Construction Loan": "Construction",
  // Cash (code phased out August 17, 2020).
  "2nd Mortgage made to cover Down Payment": "Second",
  "Credit Line (Revolving)": "HELOC",
  "FHA": "FHA",
  "Fannie Mae/Freddie Mac (Phased out because Fannie Mae does not originate loans directly to borrowers or investors. Most “new conventional” loans meet their general underwriting guidelines.)": "Conventional",
  "Balloon": "Balloon",
  "Farmers Home Administration": "FmHA",
  "Negative Amortization": "NegAm",
  // Loan Amount $1-9 billion - first 9-digits Loan Amount x 10 = actual amount.
  "Land Contract (Argmt. Of Sale)": "Land Contract",
  "Mortgage Modification": "Modification",
  "New Conventional": "Conventional",
  "Commercial": "Commercial",
  "Purchase Money Mortgage": "Seller Financing",
  "Undefined / Multiple Amounts": "Misc.",
  "Stand Alone Refi (Refinance of Original Loan)": "Refinance",
  "Seller take-back": "Seller Financing",
  // Loan Amount $10-99 billion - first 9-digits of Loan Amount x 100 = actual amount.
  "VA": "VA",
  "Future Advance Clause / Open End Mortgage": "Revolving",
  "Trade": "Exchange",
  "State Veterans (code phased out August 17, 2020)": "State Veterans",
  "Reverse Mortgage (Home Equity Conversion Mortgage)": "Reverse",
  "Non Purchase Money Mortgage": "Refinance",
  "SBA Participation Trust Deed": "SBA"
}
import { appConfig, isPellego } from "config";
import { fetcherWithCredentials } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const sendConfirmation = (token: string) => {
  const url = appConfig.apiEndpoint.confirmEmail.replace(":token", token);
  return fetcherWithCredentials(url);
};

export const ConfirmEmail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      setLoading(true);
      sendConfirmation(token)
        .then((res) => {
          if (res === "success") {
            setSuccess(true);
            setTimeout(() => navigate("/"), 3000);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, navigate]);

  return (
    <div className="my-40 h-full">
      <div className="mx-auto mt-20 max-w-md px-6 pb-4">
        <h3 className="text-3xl">Email Confirmation</h3>

        <div className="mt-4 max-w-md">
          {loading && <div>Loading...</div>}

          {!loading && (!token || error) && (
            <div>
              There was an error validating your confirmation token. Please try again or email support@
              {isPellego ? "pellego" : "lotside"}.com if the issue persists.
            </div>
          )}

          {success && <div>Email confirmed! You will be redirected to the homepage shortly.</div>}
        </div>
      </div>
    </div>
  );
};

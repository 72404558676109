import { AnnounceWidget, LoadingPhrases, NavBrowse } from "components";
import { AppreciationsMap, BrowseMap, Filters, NonMLSPanel, ResultsPanel } from "components/browse";
import { cn, titleCase } from "helpers";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useRecoilValue } from "recoil";
import { useUserSession } from "state";
import {
  buildBrowseTitleFromUrl,
  filteredSearchResultsSelector,
  useBrowse,
  useLocationSearch,
} from "state/browse";
import { useSavedDeals, useSaveDeal } from "state/proformas";

const onlyFavoritesResults = (properties: any[], isSavedDeal: (parcelId: number) => boolean) => {
  return properties.filter((property) => isSavedDeal(property.parcel_id));
};

export const Browse = () => {
  const { loading, emptyResult } = useBrowse();
  const [onlyFavorites, setOnlyFavorites] = useState(false);

  const currentUser = useUserSession();
  useSavedDeals(currentUser.isLogged);
  const { isSavedDeal } = useSaveDeal();
  const filteredSearchResults = useRecoilValue(filteredSearchResultsSelector);
  const searchResults = onlyFavorites
    ? onlyFavoritesResults(filteredSearchResults, isSavedDeal)
    : filteredSearchResults;
  const { locationSearchItems } = useLocationSearch();
  const pageTitle = buildBrowseTitleFromUrl(
    window.location.pathname + window.location.search,
    titleCase(locationSearchItems[0]?.name),
  );

  const loadingPhrases = ["Pulling Listings", "Estimating ARVs", "Estimating Rents", "Populating Data"];

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div
        id="main-frame"
        className={cn("grid h-screen w-screen place-items-center", !isMobile && "overflow-hidden")}
      >
        <div id="top-navbar" className="sticky z-40 flex h-16 w-full flex-row justify-between">
          <NavBrowse loading={loading} emptyResult={emptyResult} searchResults={searchResults} />
          {!isMobile && <Filters filteredProperties={searchResults} />}
        </div>
        <div id="main-content" className="h-screen w-screen place-items-center">
          {loading ? (
            <LoadingPhrases phrases={loadingPhrases} />
          ) : (
            <>
              <div className="flex h-screen w-full flex-row">
                {!isMobile && (
                  <div id="map-section" className="z-10 h-[calc(100vh-4rem)] w-full grow">
                    {emptyResult ? <AppreciationsMap /> : <BrowseMap />}
                  </div>
                )}
                <div
                  id="results-section"
                  className={`flex h-full shrink-0 ${isMobile ? "w-full" : "w-[calc(318px+2rem)] xl:w-[calc(636px+3rem)] 3xl:w-[calc(954px+4rem)]"}`}
                >
                  {emptyResult ? (
                    <>
                      <NonMLSPanel />
                      <AnnounceWidget />
                    </>
                  ) : (
                    <ResultsPanel
                      loading={loading}
                      searchResults={searchResults}
                      emptyResult={emptyResult}
                      onlyFavorites={onlyFavorites}
                      setOnlyFavorites={setOnlyFavorites}
                      sortedSearchResults={filteredSearchResults}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

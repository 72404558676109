import { WASHINGTON_STATE_ID } from "config";
import { cn, getListingDataByName } from "helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useUserSession } from "state";
import { useUpdateDate } from "state/proformas";

type Props = {
  propertyParcel: any;
  className?: string;
  shortSale?: boolean;
  shortRental?: boolean;
};

export const ListedBy = ({ propertyParcel, className, shortSale = false, shortRental = false }: Props) => {
  const currentUser = useUserSession();
  const most_recent = shortRental ? propertyParcel.most_recent_lease : propertyParcel.most_recent_listing;
  const isNWMLS = most_recent?.listing_source === "NWMLS";
  const updateDate = useUpdateDate(most_recent?.state_id);
  const listingData = most_recent?.listing_number && getListingDataByName(most_recent?.listing_source);

  return (
    <>
      {shortSale || shortRental ? (
        // Short listing format
        listingData || (most_recent?.listing_source && most_recent?.listing_number) ? (
          <>
            {listingData?.abbr || most_recent?.listing_source || "MLS"} #{most_recent?.listing_number}
            {listingData?.logoURL && (
              <img
                src={listingData?.logoURL}
                alt={`${listingData?.abbr} Logo`}
                className="ml-1 inline h-4 w-auto"
              />
            )}
          </>
        ) : (
          <>MLS Data Unavailable</>
        )
      ) : (
        // Long listing format
        <div
          className={cn(
            "w-full justify-center text-pretty px-2 pb-3 text-center text-xs font-light leading-5 text-gray-700 sm:leading-7",
            className && className,
          )}
        >
          {most_recent?._on_market && most_recent?.sale_type !== "Standard" && (
            <div className="inline font-bold">{most_recent?.sale_type}&nbsp;</div>
          )}
          {most_recent?.selling_company && (
            <div className="inline text-start">Sold by {most_recent?.selling_company}.&nbsp;</div>
          )}

          {/* Listing source */}
          <div className="inline items-center">
            {most_recent?.listing_source &&
              (isNWMLS ? (
                <>
                  {most_recent?.selling_company ? `Listing courtesy of ` : `Courtesy of `}
                  {most_recent?.listing_source}
                </>
              ) : (
                most_recent?.listing_company && `Listed by ${most_recent?.listing_company}`
              ))}

            {/* Listing number */}
            {most_recent?.listing_source &&
              most_recent?.listing_number &&
              most_recent?.listing_number.length > 0 &&
              (currentUser.isAgent && propertyParcel.state_id === WASHINGTON_STATE_ID ? (
                <CopyToClipboard text={most_recent?.listing_number}>
                  <a
                    className="font-medium text-blue-700"
                    href="http://www.matrix.nwmls.com/Matrix/Search/SingleFamily/MLS"
                    target="_new"
                  >
                    {isNWMLS
                      ? ` (#${most_recent?.listing_number})`
                      : ` (${most_recent?.listing_source} #${most_recent?.listing_number})`}
                  </a>
                </CopyToClipboard>
              ) : isNWMLS ? (
                ` (#${most_recent?.listing_number})`
              ) : (
                ` (${most_recent?.listing_source} #${most_recent?.listing_number})`
              ))}

            {/* Listing company */}
            {isNWMLS &&
              most_recent?.listing_company &&
              ` and ${most_recent?.listing_company} via MLS GRID as of ${updateDate}`}

            {/* Logo */}
            {listingData?.logoURL && (
              <img
                src={listingData?.logoURL}
                alt={`${listingData?.abbr} Logo`}
                className={`ml-1 inline h-${listingData?.logoHeight} w-auto`}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

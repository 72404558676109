import { yupResolver } from "@hookform/resolvers/yup";
import { FormFieldError } from "components";
import { SubmitButton } from "components/forms";
import { appConfig, isPellego } from "config";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAskAgent } from "state";
import { useProforma } from "state/proformas";
import * as Yup from "yup";

type AskAboutFormProps = {
  toEmail?: string;
};

export const AskAboutForm = ({ toEmail }: AskAboutFormProps) => {
  const { proforma } = useProforma();
  const { sendEmailToAgent } = useAskAgent();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  var address = "";

  if (proforma && proforma.parcel && proforma.parcel.full_address) {
    address = proforma.parcel.full_address;
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Your name is required"),
    email: Yup.string().required("Your email is required"),
    phone: Yup.string().required("Your phone is required"),
    message: Yup.string().required("Your message is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting, isSubmitSuccessful } = formState;

  const sendEmail = async (formData: any) => {
    await reCaptchaRef.current?.reset();
    const reCaptchaToken = await reCaptchaRef.current?.executeAsync();

    return sendEmailToAgent(
      toEmail || "",
      formData.email,
      formData.name,
      formData.phone,
      formData.message,
      reCaptchaToken || "",
      address,
    );
  };

  return (
    <form onSubmit={handleSubmit(sendEmail)}>
      <input
        type="text"
        className="mb-1 mt-2 w-full border border-gray-300 text-sm"
        placeholder="Name"
        {...register("name")}
      />
      <FormFieldError className="-mt-1" field={errors.name} />

      <input
        type="email"
        className="my-1 w-full border border-gray-300 text-sm"
        placeholder="Email"
        {...register("email")}
      />
      <FormFieldError className="-mt-1" field={errors.email} />

      <input
        type="text"
        className="my-1 w-full border border-gray-300 text-sm"
        placeholder="Phone"
        {...register("phone")}
      />
      <FormFieldError className="-mt-1" field={errors.phone} />

      <textarea
        className="my-1 block h-20 w-full border border-gray-300 p-2.5 text-sm text-gray-900 focus:border focus:border-blue-500 focus:ring-blue-500"
        placeholder="Questions?"
        {...register("message")}
      ></textarea>
      <FormFieldError className="-mt-1" field={errors.message} />

      <div className="flex flex-row gap-2 md:flex-col lg:flex-row xl:items-center">
        <SubmitButton
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          isSuccessful={isSubmitSuccessful && !isSubmitting}
          buttonClassName="text-white self-center xl:self-start bg-[#7AA0BA] hover:bg-[#4f7b98] justify-center h-8 w-fit xl:px-8 md:w-full lg:w-fit lg:px-6 mt-2 mb-1 md:mb-0"
        >
          {isSubmitSuccessful && !isSubmitting ? "Sent!" : "Submit"}
        </SubmitButton>

        <div className="content-center text-start text-[0.6rem] text-gray-700 md:text-center lg:text-start xl:mt-1">
          <ReCAPTCHA size="invisible" sitekey={appConfig.reCaptchaPublicKey} ref={reCaptchaRef} />
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy" className="underline">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="https://policies.google.com/terms" className="underline">
            Terms of Service
          </a>{" "}
          apply.
        </div>
      </div>
    </form>
  );
};

type Props = {
  title: string;
  agent?: any;
  currentUserIsAgent?: boolean;
};

export const AskAbout = ({ title, agent }: Props) => {
  return (
    <div className="flex w-full max-w-5xl flex-col justify-center bg-[#FFF2CC] p-4 shadow-lg sm:p-2.5 xl:p-5">
      <div className="mb-1 text-[15px] font-bold md:text-lg">{title}</div>
      <div className="flex w-full flex-col sm:flex-row">
        <div className="w-full">
          <AskAboutForm toEmail={`gideon@${isPellego ? "pellego" : "lotside"}.com`} />
        </div>
      </div>
    </div>
  );
};

export const AskAboutWithAgent = ({ title, agent, currentUserIsAgent }: Props) => {
  return (
    <div className="flex w-full max-w-5xl flex-col justify-center bg-[#FFF2CC] p-4 shadow-lg sm:p-2.5 xl:p-5">
      <div className="mb-2 flex w-full flex-col items-center md:mb-1 md:flex-row md:items-start xl:mb-3">
        <div
          className="flex size-full min-h-[125px] shrink-0 bg-cover bg-top bg-no-repeat md:max-w-[70px] lg:max-w-[115px] xl:max-w-[125px]"
          style={{
            backgroundImage: `url('${
              agent.avatar
                ? agent.avatar
                : "https://cdn.davinci.pellego.com/static/images/core/avatar-default-bw.png"
            }')`,
          }}
        />
        <div className="flex w-full flex-col items-center md:ml-3 md:w-full md:grow md:items-start md:text-sm md:leading-5 lg:leading-6 xl:min-h-[125px]">
          <div className="text-center font-bold md:hidden md:text-start lg:block lg:text-[16px] xl:text-lg">
            {title}
          </div>
          {agent?.description && (
            <div className="mb-1 text-pretty text-center md:text-start md:text-sm lg:text-[15px] xl:text-[16px] xl:leading-6">
              {agent.description}
            </div>
          )}
          {currentUserIsAgent && (
            <>
              {agent.avatar && !agent.description && (
                <div className="mb-1 text-pretty text-center md:text-start md:text-sm lg:text-[15px] xl:text-[16px] xl:leading-6">
                  Email{" "}
                  <a href={`mailto:gideon@${isPellego ? "pellego" : "lotside"}.com`} className="text-blue-400">
                    gideon@{isPellego ? "pellego" : "lotside"}.com
                  </a>{" "}
                  to edit this description.
                </div>
              )}
              {!agent.avatar && (
                <div className="mb-1 text-pretty text-center md:text-start md:text-sm lg:text-[15px] xl:text-[16px] xl:leading-6">
                  <Link to="/account" className="text-blue-400">
                    Edit profile
                  </Link>
                </div>
              )}
            </>
          )}
          <div className="text-center font-bold md:text-start md:text-sm lg:text-[15px] xl:text-[16px] xl:leading-6">
            {agent?.name_first} {agent?.name_last}
          </div>
          <div className="text-pretty text-center md:text-start md:text-sm lg:text-[15px] xl:text-[16px] xl:leading-6">
            Real Estate Agent
          </div>
          <div className="text-pretty text-center md:text-start md:text-sm lg:text-[15px] xl:text-[16px] xl:leading-6">
            {agent?.brokerage}
          </div>
        </div>
      </div>
      <AskAboutForm toEmail={agent?.email} />
    </div>
  );
};

/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { isPellego } from "config";
import { Fragment } from "react";

const SolutionsMenuItems = [
  { name: "Agents Solutions", href: `https://www.${isPellego ? "pellego" : "lotside"}.com/plans/agents` },
  { name: "Buyer Solutions", href: `https://www.${isPellego ? "pellego" : "lotside"}.com/buyers` },
  { name: "Wholesaler Solutions", href: `https://www.${isPellego ? "pellego" : "lotside"}.com/wholesalers` },
];

export const NavBarSolutions = () => {
  return (
    <Menu as="div" className="relative inline-block items-center text-left">
      <Menu.Button className="inline-flex w-full basis-1/3 items-center justify-center px-2 py-1.5 text-center font-medium uppercase text-gray-600 focus:outline-none focus:ring-0 focus:ring-offset-0 sm:space-x-2 sm:px-4">
        Solutions
        <ChevronDownIcon className="-mr-1 ml-2 h-6 w-5" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {SolutionsMenuItems.map(({ name, href }, index) => (
              <Menu.Item key={`htmi-${index}`}>
                {({ active }) => (
                  <a
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    className={`${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                    } block px-4 py-2 text-sm`}
                  >
                    {name}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

import { appConfig } from "config";
import { formatPropertyName } from "helpers";
import L from "leaflet";
import { ComparableBaseType } from "state/proformas/comparables";
import "@maplibre/maplibre-gl-leaflet/leaflet-maplibre-gl";

const makeCompMapIcon = (status: string, idx: number) => {
  const green = "#70a08e",
    orange = "#F7931E",
    lightgray = "#eeeeee",
    lightblue = "#2196f3";

  let icon_color;

  switch (status) {
    case "highlighted":
      icon_color = lightblue;
      break;
    case "excluded":
      icon_color = lightgray;
      break;
    case "included":
      icon_color = green;
      break;
    case "subject":
      icon_color = orange;
      break;
    case "suggested":
      icon_color = green;
      break;
    default:
      icon_color = lightgray;
  }

  const svgIcon = L.divIcon({
    html: `<svg fill=${icon_color} width="32px" height="32px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke="#000" stroke-width="3"><path d="M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z"></path></g><g><path d="M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z"></path></g></svg>
          <span class=${status}>${status === "subject" ? "•" : String.fromCharCode("A".charCodeAt(0) + idx)
      }</span>`,
    className: "comp-icon",
    iconAnchor: [14, 30],
  });
  return svgIcon;
};

export class LeafletCompMapService {
  subjectProp: any;
  map: L.Map | null = null;
  compMarker: L.Marker | null = null;

  constructor(subjectProp: any) {
    this.subjectProp = subjectProp;
  }

  createMap(domRef: HTMLDivElement, idx: number) {
    let subjectPos = new L.LatLng(this.subjectProp.parcel.latitude, this.subjectProp.parcel.longitude);

    const streetMap = L.maplibreGL({
      style: `https://api.maptiler.com/maps/2eadbf66-831a-4e49-9604-48d6515c3a10/style.json?key=${appConfig.mapTilerApiKey}`,
    });

    this.map = L.map(domRef, {
      zoom: 12,
      maxZoom: 17,
      attributionControl: true,
      zoomControl: false,
      layers: [streetMap],
      center: subjectPos,
      scrollWheelZoom: false,
    });

    L.control.zoom({ position: "topleft" }).addTo(this.map);

    const subjectMarkerOptions = {
      title: formatPropertyName(this.subjectProp.parcel.street_address),
      icon: makeCompMapIcon("subject", 0),
      riseOnHover: true,
      zIndexOffset: 1100,
    };

    if (this.subjectProp.parcel.most_recent_listing?.dont_show_map_link) return;

    const subjectMarker = new L.Marker(subjectPos, subjectMarkerOptions);
    subjectMarker.addTo(this.map);
  }

  updateMarker(comp: ComparableBaseType, idx: number) {
    if (!this.map) return;

    this.compMarker?.remove();

    let subjectPos = new L.LatLng(this.subjectProp.parcel.latitude, this.subjectProp.parcel.longitude);

    const bounds = new L.LatLngBounds([]);

    if (!comp.parcel.most_recent_listing?.dont_show_map_link) {
      this.compMarker = this.makeMarker(comp, bounds, idx);
      this.compMarker.addTo(this.map!);
    }

    bounds.extend(subjectPos);

    this.map.fitBounds(bounds.pad(0.2));
  }

  makeMarker(comp: ComparableBaseType, bounds: L.LatLngBounds, idx: number) {
    const position = new L.LatLng(comp.parcel.latitude, comp.parcel.longitude);
    bounds.extend(position);

    const status = comp.status;
    const markerOptions = {
      title: formatPropertyName(comp.parcel.street_address),
      idx: comp._idx,
      parcel_id: comp.parcel._id,
      status: status,
      icon: makeCompMapIcon(status, comp._idx),
      riseOnHover: true,
      zIndexOffset: 1000,
    };
    const marker = new L.Marker(position, markerOptions);
    return marker;
  }
}

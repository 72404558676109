import { yupResolver } from "@hookform/resolvers/yup";
import { FormFieldError } from "components";
import { SubmitButton } from "components/forms";
import { appConfig } from "config";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useAskAgent } from "state";
import { useProforma } from "state/proformas";
import * as Yup from "yup";

type Props = {
  toEmail: string;
  messagePlceholder?: string;
  submitButtonLabel?: string;
  onSubmit?: () => void;
};

export const ContactForm = ({ toEmail, messagePlceholder, submitButtonLabel, onSubmit }: Props) => {
  const { sendEmailToAgent } = useAskAgent();
  const { proforma } = useProforma();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  var address = "";

  if (proforma && proforma.parcel && proforma.parcel.full_address) {
    address = proforma.parcel.full_address;
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Your name is required"),
    email: Yup.string().required("Your email is required"),
    phone: Yup.string().required("Your phone is required"),
    message: Yup.string().required("Your message is required"),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting, isSubmitSuccessful } = formState;

  const sendEmail = async (formData: any) => {
    await reCaptchaRef.current?.reset();
    const reCaptchaToken = await reCaptchaRef.current?.executeAsync();

    if (onSubmit) {
      onSubmit();
    }
    return sendEmailToAgent(
      toEmail || "",
      formData.email,
      formData.name,
      formData.phone,
      formData.message,
      reCaptchaToken || "",
      address,
    );
  };

  return (
    <form onSubmit={handleSubmit(sendEmail)}>
      <input
        type="text"
        className="my-2 w-full border border-gray-300 text-sm"
        placeholder="Name"
        {...register("name")}
      />
      <FormFieldError field={errors.name} />

      <input
        type="email"
        className="my-2 w-full border border-gray-300 text-sm"
        placeholder="Email"
        {...register("email")}
      />
      <FormFieldError field={errors.email} />

      <input
        type="text"
        className="my-2 w-full border border-gray-300 text-sm"
        placeholder="Phone"
        {...register("phone")}
      />
      <FormFieldError field={errors.phone} />

      <textarea
        className="mt-2 block h-28 w-full border border-gray-300 p-2.5 text-sm text-gray-900 focus:border focus:border-blue-500 focus:ring-blue-500"
        placeholder={messagePlceholder || "message..."}
        {...register("message")}
      ></textarea>
      <FormFieldError field={errors.message} />

      <ReCAPTCHA size="invisible" sitekey={appConfig.reCaptchaPublicKey} ref={reCaptchaRef} />

      <SubmitButton
        isSubmitting={isSubmitting}
        disabled={isSubmitting}
        isSuccessful={isSubmitSuccessful && !isSubmitting}
        buttonClassName="mt-4 text-center flex flex-col items-center px-4 sm:px-8 py-3 font-normal text-md rounded-sm shadow-sm transition ease-in-out duration-150 text-white bg-[#7AA0BA] hover:bg-[#4f7b98] whitespace-nowrap w-full"
      >
        {isSubmitSuccessful && !isSubmitting ? "Sent!" : submitButtonLabel || "Send message"}
      </SubmitButton>
    </form>
  );
};

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { AskAboutForm } from "components/proformas";
import { cn } from "helpers";
import { useState } from "react";
import { Link } from "react-router-dom";
import { isPellego } from "config";

type Props = {
  agent?: any;
  currentUserIsAgent?: boolean;
};

export const AskAboutNotice = ({ agent, currentUserIsAgent }: Props) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={cn(
        "align-start absolute bottom-0 left-0 z-10 flex w-full shrink-0 cursor-pointer flex-col justify-center bg-[#FFF2CC] text-sm shadow-lg -shadow-y-2",
        show ? "h-fit p-3" : "h-12 p-1",
      )}
    >
      <div className="absolute right-4 top-1 size-5 cursor-pointer" onClick={() => setShow(!show)}>
        {show ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </div>
      <div className="flex w-full justify-start" onClick={() => setShow(!show)}>
        {agent &&
          (show ? (
            <div className="flex w-full flex-row items-start">
              <div
                className="min-h-[140px] flex size-full max-w-[30%] shrink-0 bg-cover bg-top bg-no-repeat sm:max-w-[25%]"
                style={{
                  backgroundImage: `url('${
                    agent?.avatar
                      ? agent?.avatar
                      : "https://cdn.davinci.pellego.com/static/images/core/avatar-default-bw.png"
                  }')`,
                }}
              />
              <div className="ml-3 flex w-full grow flex-col items-start justify-center text-pretty text-start text-sm sm:text-[16px]">
                <div className="font-bold">Ask about this property</div>
                {agent?.description && <div>{agent?.description}</div>}
                {currentUserIsAgent && (
                  <>
                    {agent.avatar && !agent.description && (
                      <div>
                        Email{" "}
                        <a href={`mailto:gideon@${isPellego ? "pellego" : "lotside"}.com`} className="text-blue-400">
                          gideon@{isPellego ? "pellego" : "lotside"}.com
                        </a>{" "}
                        to edit this description.
                      </div>
                    )}
                    {!agent.avatar && (
                      <div>
                        <Link to="/account" className="text-blue-400">
                          Edit profile
                        </Link>
                      </div>
                    )}
                  </>
                )}
                <div className="mt-3 font-bold">
                  {agent?.name_first} {agent?.name_last}
                </div>
                <div>Real Estate Agent</div>
                <div>{agent?.brokerage}</div>
              </div>
            </div>
          ) : (
            <div className="flex flex-row">
              <img src={agent?.avatar} className="size-11" alt={`${agent?.name_first} ${agent?.name_last}`} />
              <div className="ml-2 flex flex-col justify-center text-sm">
                <div className="font-bold">Ask about this property</div>
                <div className="font-normal">
                  {agent?.name_first} {agent?.name_last}{(agent?.name_first || agent?.name_last)? ", Real Estate Agent":"Real Estate Agent"}
                </div>
              </div>
            </div>
          ))}
        {!agent && (
          <div className={cn("px-1 text-sm font-bold sm:text-[16px]", !show && "px-3 sm:text-sm")}>
            Ask about this property
          </div>
        )}
      </div>
      {show && (
        <div className="mt-1">
          <AskAboutForm toEmail={agent?.email || `gideon@${isPellego ? "pellego" : "lotside"}.com`} />
        </div>
      )}
    </div>
  );
};

import { XIcon } from "@heroicons/react/solid";
import { ModalWrapper } from "components";
import { useEffect, useRef } from "react";
import { ImageInPhotoModal } from "./ImageInPhotoModal";

type Props = {
  photos: any[];
  isOpen: boolean;
  closeModal: any;
  showIdx: number | null;
};

export const PhotosModalDesktop = ({ photos, isOpen, closeModal, showIdx }: Props) => {
  const containerRefLg = useRef<HTMLDivElement>(null);
  const containerRefAll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && showIdx !== null && containerRefLg.current) {
      const photoElementLg = containerRefLg.current.querySelector(`#photo-${showIdx}`);
      if (photoElementLg) {
        photoElementLg.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
    return;
  }, [containerRefLg, showIdx, isOpen]);

  useEffect(() => {
    if (isOpen && showIdx !== null && containerRefAll.current) {
      const photoElementAll = containerRefAll.current.querySelector(`#photo-all-${showIdx}`);
      if (photoElementAll) {
        photoElementAll.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
    return;
  }, [containerRefAll, showIdx, isOpen]);

  return (
    <ModalWrapper className="z-[2147483001]" isOpen={isOpen} closeModal={closeModal} overlayOpacity={90}>
      <div className="absolute top-0 flex h-screen w-full flex-col items-center bg-white p-0">
        <XIcon
          className="absolute right-3 top-2 z-10 size-7 cursor-pointer rounded-full bg-white p-1 outline-none 2xl:size-8 3xl:size-9 4xl:size-11"
          aria-hidden="true"
          onClick={closeModal}
        />
        <div
          ref={containerRefLg}
          className="flex w-full snap-y snap-mandatory flex-wrap overflow-y-scroll scroll-smooth px-1"
        >
          <div className="hidden w-1/2 flex-col flex-wrap space-y-1 pr-0.5 lg:flex">
            {photos.map(
              (photo, index) =>
                index % 2 === 0 && (
                  <div id={`photo-${index}`} key={`photo-${index}`}>
                    <ImageInPhotoModal photo={photo} />
                  </div>
                ),
            )}
          </div>
          <div className="hidden w-1/2 flex-col flex-wrap space-y-1 pl-0.5 lg:flex">
            {photos.map(
              (photo, index) =>
                index % 2 === 1 && (
                  <div id={`photo-${index}`} key={`photo-${index}`}>
                    <ImageInPhotoModal photo={photo} />
                  </div>
                ),
            )}
          </div>
        </div>
        <div
          ref={containerRefAll}
          className="flex w-full snap-y snap-mandatory flex-wrap overflow-y-scroll scroll-smooth px-1"
        >
          <div className="flex w-full flex-col flex-wrap space-y-1 lg:hidden">
            {photos.map((photo, index) => (
              <div id={`photo-all-${index}`} key={`photo-all-${index}`}>
                <ImageInPhotoModal photo={photo} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

import { PhotographIcon } from "@heroicons/react/outline";
import {
  rentalComphighlightHandlerAtom,
  rentalCompModalShowIdxAtom,
} from "components/proformas/comparables/";
import { StreetView } from "components/proformas/details";
import { buildCompUrl, formatDateShort, formatPropertyName, hasValue } from "helpers";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { EQUIVALENT_STRUCT_TYPES } from "state/browse/useFilters";
import { useProforma } from "state/proformas";
import { RentalComparableType } from "state/proformas/comparables/";
import { ListedBy } from "components";

type Props = {
  comparable: RentalComparableType;
  compStats: any[];
  isRentalCompsViewAdjustEmpty: boolean;
};

export const RentalCompPropertyCard = ({ comparable, compStats, isRentalCompsViewAdjustEmpty }: Props) => {
  const { proforma } = useProforma();
  const setCompModalShowIdx = useSetRecoilState(rentalCompModalShowIdxAtom);
  const highlightHandler = useRecoilValue(rentalComphighlightHandlerAtom);

  const onMouseHover = (idx: number, highlighted: boolean) => {
    // Highlight property on Map
    if (highlightHandler) highlightHandler(idx, highlighted);
  };
  const showCompAddress = !(comparable.parcel?.most_recent_listing?.dont_show_address === true);
  const isMissingThumbnail =
    !comparable.parcel?.thumbnail_url ||
    comparable.parcel?.thumbnail_url === "https://cdn.davinci.pellego.com/static/images/core/missing.png";

  return (
    <div
      className="flex snap-start snap-normal flex-row sm:first:pl-4"
      onMouseEnter={() => onMouseHover(comparable._idx, true)}
      onMouseLeave={() => onMouseHover(comparable._idx, false)}
    >
      <div className="w-44 min-w-44 whitespace-nowrap p-3 pr-0 sm:w-56 sm:min-w-56 sm:p-2 sm:py-4">
        <div className="flex h-[60px] flex-col items-start">
          <div className="text-sm font-bold">
            Comp {String.fromCharCode("A".charCodeAt(0) + comparable._idx)}
            <span className="px-2 text-xs font-light text-gray-700">
              (<NumberFormat value={comparable.distance} displayType="text" decimalScale={2} /> miles)
            </span>
          </div>
          {showCompAddress ? (
            <Link to={buildCompUrl(comparable)} target="_blank">
              <div className="truncate text-xs font-light text-blue-700">
                {formatPropertyName(comparable.parcel?.street_address)}
              </div>
            </Link>
          ) : (
            <div className="text-xs font-light text-blue-700">Address Hidden by Owner</div>
          )}
          <div className="text-xs font-light text-gray-700">
            <ListedBy propertyParcel={comparable.parcel} shortRental={true} />
          </div>
        </div>

        <LazyLoadComponent>
          {isMissingThumbnail ? (
            <div onClick={() => setCompModalShowIdx(comparable._idx)}>
              <StreetView
                otherClasses="flex cursor-pointer items-end rounded-sm bg-blue-300"
                sizeClasses="h-36 w-full"
                parcel={comparable.parcel}
                titles={false}
              />
            </div>
          ) : (
            <div
              className="bg-[background-size: 100% 100%;] relative flex h-36 w-full cursor-pointer items-end rounded-sm bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url('${comparable.parcel?.thumbnail_url}')` }}
              onClick={() => setCompModalShowIdx(comparable._idx)}
            >
              <div className="absolute bottom-2 right-2 z-10 flex items-center text-xs text-white">
                <PhotographIcon className="mr-1 w-5" aria-hidden="true" />
                {comparable.photo_count || comparable.parcel?.most_recent_listing?.photos?.length || 0}
              </div>
              <div className="absolute bottom-0 h-9 w-full bg-gradient-to-t from-gray-900/70 to-transparent" />
            </div>
          )}
        </LazyLoadComponent>

        <div
          className="mt-4 cursor-pointer text-xs leading-7"
          onClick={() => setCompModalShowIdx(comparable._idx)}
        >
          <div className="flex flex-row justify-items-stretch">
            <div className="w-full text-right text-sm font-medium leading-4">
              <NumberFormat
                value={comparable.monthly_rent}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
          <div className="flex w-full text-xs font-normal leading-4 text-gray-400">
            <span className="flex w-full justify-end whitespace-normal text-right leading-4">
              Rented {formatDateShort(comparable.lease_lease_date, false)}
            </span>
          </div>

          <div className="mt-2 flex flex-row justify-items-stretch py-0">
            <div className="mt-2 w-1/2 text-left text-xs font-bold text-gray-700">Comp</div>
            <div className="mt-2 w-1/2 text-right text-xs font-bold text-gray-700">Adjustments</div>
          </div>

          {compStats.map((stat) =>
            (stat.key === "hoa_dues" &&
              (stat.subjectValue === "$0" || stat.subjectValue === 0 || stat.subjectValue === null)) ||
            (stat.key === "structure_style" &&
              !EQUIVALENT_STRUCT_TYPES.includes(proforma.parcel._characteristics.structure_type)) ||
            (stat.key === "view_type" && isRentalCompsViewAdjustEmpty) ? null : (
              <div
                key={stat.key}
                className="flex flex-row justify-items-stretch gap-2 border-b border-gray-200 py-0"
              >
                <div
                  className={`w-1/2 overflow-hidden text-ellipsis whitespace-nowrap ${!hasValue(comparable.parcel._characteristics[stat.key]) ? "text-[0.6rem] italic text-gray-300" : ""}`}
                >
                  {hasValue(comparable.parcel._characteristics[stat.key]) ? (
                    <>
                      {stat.format === "currency" && (
                        <NumberFormat
                          value={comparable.parcel._characteristics[stat.key]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix="$ "
                          decimalScale={0}
                        />
                      )}
                      {stat.format === "number" && (
                        <NumberFormat
                          value={comparable.parcel._characteristics[stat.key]}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={comparable.parcel._characteristics[stat.key] % 1 !== 0 ? 2 : 0}
                        />
                      )}
                      {stat.format !== "number" && stat.format !== "currency" && (
                        <>{comparable.parcel._characteristics[stat.key]}</>
                      )}
                    </>
                  ) : (
                    <>{stat.fallback}</>
                  )}
                </div>

                <div className="w-1/2 text-right">
                  {comparable.rental_cost_diffs[stat.compsKey ? stat.compsKey : stat.key] ? (
                    <NumberFormat
                      className="font-bold"
                      value={comparable.rental_cost_diffs[stat.compsKey ? stat.compsKey : stat.key]}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$ "
                      decimalScale={0}
                    />
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
            ),
          )}

          <div className="mt-2 text-right text-xs font-medium text-gray-700">Adjusted Rent</div>
          <div className="flex flex-row justify-items-stretch">
            <div className="w-full text-right text-sm font-medium leading-4">
              <NumberFormat
                className="font-bold"
                value={comparable.projected_rent}
                displayType="text"
                thousandSeparator={true}
                prefix="$ "
                decimalScale={0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
